import {Component, NgZone, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import {FirestoreService} from '../../shared/services/firestore/firestore.service';
import {AuthenticationService} from '../../authentication/authentication.service';
import {CONTACT} from '../../models/contact.model';
import {BehaviorSubject, Subject} from 'rxjs';
import {IpcService} from '../../shared/services/ipc/ipc.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit, OnDestroy {

  private _unsubscribe: Subject<any>;
  public contacts: BehaviorSubject<CONTACT[]> = new BehaviorSubject<CONTACT[]>([]);
  @Output() chatWithContact = new EventEmitter<CONTACT>();

  constructor(private firestoreService: FirestoreService, private authService: AuthenticationService, private ipcService: IpcService, public zone: NgZone) {
  }

  async ngOnInit(): Promise<any> {
    this._unsubscribe = new Subject<any>();
    await this.initializeMyContact();
    this.subscribeToContacts();
  }


  async initializeMyContact(): Promise<any> { // create my contact, if does not already exist in firebase ::
    try {
      this.authService.loggedInUser
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(async user => {
          if(user) {
            let contact: CONTACT = new CONTACT(this.firestoreService, user, this.ipcService.branchInformation.value);
            let upsertMe = await this.firestoreService.upsert<CONTACT>(`chatapp/${this.ipcService.branchInformation.value.vendorId}/contacts/${contact.userId}`, contact.contact);
            console.log('upsertMe', upsertMe);
          }
        });
      return Promise.resolve();
    } catch (e) {
      console.error('upsertMeError', e);
      return Promise.reject(e);
    }
  }

  subscribeToContacts(): any {
    this.firestoreService.colWithIds$<CONTACT[]>(`chatapp/${this.ipcService.branchInformation.value.vendorId}/contacts`, ref => ref.where('status', '==', 0))
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(contacts => {
        if (contacts) {
          this.zone.run(() => {
            this.contacts.next(contacts);
          });
        }
      });
  }

  openConversation(contact: CONTACT): void {
    this.chatWithContact.emit(contact);
  }

  getInitials(contact: CONTACT): string {
    let a = contact.displayName.split(' ');
    let initials = a.map(x => {
      return x[0].toUpperCase();
    });
    if(initials.length > 1)
      return `${initials[0]}${initials[1]}`;
    else
      return initials[0];
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }


}
