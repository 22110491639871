import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AngularFireStorageModule} from "@angular/fire/storage";
import {AngularFireAnalyticsModule} from "@angular/fire/analytics";
import {AngularFirestoreModule} from "@angular/fire/firestore";
import {AngularFireModule} from "@angular/fire";
import {BaseComponent} from './base/base.component';
import {AuthenticationModule} from "./authentication/authentication.module";
import {DashboardModule} from "./dashboard/dashboard.module";
import {RouterModule} from "@angular/router";
import {ToastrModule} from "ngx-toastr";
import {SDKBrowserModule} from "./shared/sdk";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {NgxSpinnerModule} from "ngx-spinner";
import {LoadingBarHttpClientModule} from "@ngx-loading-bar/http-client";
import {LoadingBarRouterModule} from "@ngx-loading-bar/router";
import {LoggerService} from "./shared/services/logger.service";
import {ConfigService} from "./shared/services/config.service";
import {environment} from "../environments/environment";
import { FooterComponent } from './shared/footer/footer.component';
import { NavHeaderComponent } from './shared/nav-header/nav-header.component';
import {SharedService} from "./shared.service";
import { NavigationComponent } from './shared/navigation/navigation.component';
import { HeaderComponent } from './shared/header/header.component';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpClientModule} from "@angular/common/http";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {MatListModule} from "@angular/material/list";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatInputModule} from "@angular/material/input";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatTabsModule} from "@angular/material/tabs";
import {MatBadgeModule} from "@angular/material/badge";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {MatButtonModule} from "@angular/material/button";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatCardModule} from "@angular/material/card";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRadioModule} from "@angular/material/radio";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatChipsModule} from "@angular/material/chips";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule, MatRippleModule} from "@angular/material/core";
import {MatDialogModule} from "@angular/material/dialog";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatMenuModule} from "@angular/material/menu";
import {MatDividerModule} from "@angular/material/divider";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatSelectModule} from "@angular/material/select";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatSliderModule} from "@angular/material/slider";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatSortModule} from "@angular/material/sort";
import {MatStepperModule} from "@angular/material/stepper";
import {MatTableModule} from "@angular/material/table";
import {MatTreeModule} from "@angular/material/tree";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MetismenuAngularModule} from "@metismenu/angular";
import {ComponentsModule} from "./components/components.module";
import {FirestoreService} from "./shared/services/firestore/firestore.service";
import {IpcService} from './shared/services/ipc/ipc.service';
import {ToastService} from './shared/services/toast/toast.service';
import {ToastsContainer} from './shared/services/toast/toasts-container.component';
import {ChatModule} from './chat/chat.module';
import {ErrorHandlerService} from "./shared/services/error-handler/error-handler.service";
import {InternalApiService} from "./shared/services/internalapi/internalapi.service";
import {DataService} from './shared/services/data/data.service';
import {ProductsListService} from './products/products-list/products-list.service';
import {NgxElectronModule} from 'ngx-electron';
import {ImageService} from './shared/services/image/image.service';

export function initProviderFactory(provider: ConfigService) {
  return () => provider.initialize();
}


@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    FooterComponent,
    NavHeaderComponent,
    NavigationComponent,
    HeaderComponent,
    ToastsContainer
  ],
  imports: [
    ChatModule,
    ComponentsModule,
    PerfectScrollbarModule,
    NgbModule,
    HttpClientModule,
    AuthenticationModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireStorageModule, // storage
    AngularFirestoreModule,
    AngularFireAnalyticsModule,
    AngularFireModule.initializeApp(environment.firebase),
    SDKBrowserModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    SweetAlert2Module.forRoot(),
    NgxSpinnerModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    MetismenuAngularModule,

    MatListModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatIconModule,
    MatTabsModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatRadioModule,
    DragDropModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatToolbarModule,
    MatMenuModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTooltipModule,
    MatTreeModule,

    NgxElectronModule
  ],
  providers: [
    ImageService,
    DataService,
    InternalApiService,
    HttpClientModule,
    ProductsListService,
    NgbActiveModal,
    ToastService,
    IpcService,
    FirestoreService,
    ConfigService,
    SharedService,
    LoggerService,
    {provide: ErrorHandler, useClass: ErrorHandlerService},
    {provide: APP_INITIALIZER, useFactory: initProviderFactory, deps: [ConfigService], multi: true},
  ],
  exports: [RouterModule, ComponentsModule],
  bootstrap: [AppComponent]
})
export class AppModule {
}


