<ng-template #addEditPrinter let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{printer?.name ? 'Edit ' + printer?.name : 'Add Printer'}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="form-validation">
      <form class="form-valide" [formGroup]="printerForm" novalidate>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group row">
              <label class="col-lg-4 col-form-label" for="name">Name
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-6">
                <input type="text" class="form-control" id="name" formControlName="name" placeholder="Enter a printer name..">
                <div *ngIf="printerForm.controls['name'].invalid && (printerForm.controls['name'].dirty || printerForm.controls['name'].touched)" class="text-danger fs-13 mt-1">

                  <div *ngIf="printerForm.controls['name'].errors.required">
                    A printer name is required.
                  </div>
                </div>
              </div>

            </div>

            <div class="form-group row">
              <label class="col-lg-4 col-form-label" for="tmnhwidhex">Terminal ID
                <span class="text-danger"></span>
              </label>
              <div class="col-lg-6">
                <input type="text" class="form-control" id="tmnhwidhex" formControlName="tmnhwidhex" placeholder="Enter a terminal id if USB.">
              </div>

            </div>

            <div class="form-group row">
              <label class="col-lg-4 col-form-label" for="skill">Communication Interface
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-6">
                <select class="form-control default-select" id="interface" formControlName="interface" >
                  <option value="">Please select</option>
                  <option value="ethernet">Ethernet / Wifi</option>
                  <option value="serial">Serial</option>
                  <option value="usb">USB</option>
                </select>
                <div *ngIf="printerForm.controls['interface'].invalid && (printerForm.controls['interface'].dirty || printerForm.controls['interface'].touched)" class="text-danger fs-13 mt-1">

                  <div *ngIf="printerForm.controls['interface'].errors.required">
                    Select Communication Interface.
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-lg-4 col-form-label" for="name">IP Address
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-6">
                <input type="text" class="form-control" id="ipaddress" formControlName="ipaddress" placeholder="Enter the printer ip address..">
                <div *ngIf="printerForm.controls['interface'].value === 'ethernet' && (printerForm.controls['ipaddress'].invalid && (printerForm.controls['ipaddress'].dirty || printerForm.controls['ipaddress'].touched))" class="text-danger fs-13 mt-1">

                  <div *ngIf="printerForm.controls['ipaddress'].errors.required">
                    A printer ip address is required.
                  </div>
                </div>
              </div>

            </div>

            <div class="form-group row">
              <label class="col-lg-4 col-form-label"><a
                href="javascript:void(0)">Is Cashier</a> <span
                class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                <label class="css-control css-control-primary css-checkbox" for="iscashier">

                  <input type="checkbox" class="css-control-input mr-2" id="iscashier" value="1" formControlName="iscashier">

                </label>

              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label"><a
                href="javascript:void(0)">Is Kitchen</a> <span
                class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                <label class="css-control css-control-primary css-checkbox" for="iskitchen">

                  <input type="checkbox" class="css-control-input mr-2" id="iskitchen" value="1" formControlName="iskitchen">

                </label>

              </div>
            </div>

          </div>
        </div>
      </form>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>
