/* tslint:disable */
import { Injectable } from '@angular/core';
import { User } from '../../models/User';
import { Vendor } from '../../models/Vendor';
import { Order } from '../../models/Order';
import { Profile } from '../../models/Profile';
import { Category } from '../../models/Category';
import { Product } from '../../models/Product';
import { AttributeGroup } from '../../models/AttributeGroup';
import { Branch } from '../../models/Branch';
import { Voucher } from '../../models/Voucher';
import { Subcategory } from '../../models/Subcategory';
import { Collection } from '../../models/Collection';
import { Feed } from '../../models/Feed';
import { ImageFile } from '../../models/ImageFile';
import { Config } from '../../models/Config';
import { DeliveryAddress } from '../../models/DeliveryAddress';
import { AddOnGroup } from '../../models/AddOnGroup';
import { CheckoutSession } from '../../models/CheckoutSession';
import { Delivery } from '../../models/Delivery';
import { Transaction } from '../../models/Transaction';
import { ProductReview } from '../../models/ProductReview';
import { TelegramRegistration } from '../../models/TelegramRegistration';
import { DirectDeposit } from '../../models/DirectDeposit';
import { SalesCampaign } from '../../models/SalesCampaign';
import { Tag } from '../../models/Tag';
import { Terminal } from '../../models/Terminal';
import { Printer } from '../../models/Printer';
import { UserGroup } from '../../models/UserGroup';
import { OfflineDataLog } from '../../models/OfflineDataLog';
import { ProductInventory } from '../../models/ProductInventory';
import { Refund } from '../../models/Refund';
import { Coupon } from '../../models/Coupon';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    User: User,
    Vendor: Vendor,
    Order: Order,
    Profile: Profile,
    Category: Category,
    Product: Product,
    AttributeGroup: AttributeGroup,
    Branch: Branch,
    Voucher: Voucher,
    Subcategory: Subcategory,
    Collection: Collection,
    Feed: Feed,
    ImageFile: ImageFile,
    Config: Config,
    DeliveryAddress: DeliveryAddress,
    AddOnGroup: AddOnGroup,
    CheckoutSession: CheckoutSession,
    Delivery: Delivery,
    Transaction: Transaction,
    ProductReview: ProductReview,
    TelegramRegistration: TelegramRegistration,
    DirectDeposit: DirectDeposit,
    SalesCampaign: SalesCampaign,
    Tag: Tag,
    Terminal: Terminal,
    Printer: Printer,
    UserGroup: UserGroup,
    OfflineDataLog: OfflineDataLog,
    ProductInventory: ProductInventory,
    Refund: Refund,
    Coupon: Coupon,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
