/* tslint:disable */
import {
  Profile,
  Voucher,
  Order,
  Transaction
} from '../index';

declare var Object: any;
export interface DirectDepositInterface {
  "type"?: string;
  "profileId": any;
  "displayName"?: string;
  "email"?: string;
  "phone"?: string;
  "fulfillmentDate"?: Date;
  "voucherId"?: any;
  "orderId"?: number;
  "orderProducts"?: Array<any>;
  "amount"?: number;
  "remarks"?: string;
  "staffRemarks"?: string;
  "images": Array<any>;
  "transactionId"?: number;
  "status"?: number;
  "transactionData"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "updatedBy"?: any;
  "id"?: number;
  profile?: Profile;
  voucher?: Voucher;
  order?: Order;
  transaction?: Transaction;
}

export class DirectDeposit implements DirectDepositInterface {
  "type": string;
  "profileId": any;
  "displayName": string;
  "email": string;
  "phone": string;
  "fulfillmentDate": Date;
  "voucherId": any;
  "orderId": number;
  "orderProducts": Array<any>;
  "amount": number;
  "remarks": string;
  "staffRemarks": string;
  "images": Array<any>;
  "transactionId": number;
  "status": number;
  "transactionData": any;
  "createdAt": Date;
  "updatedAt": Date;
  "updatedBy": any;
  "id": number;
  profile: Profile;
  voucher: Voucher;
  order: Order;
  transaction: Transaction;
  constructor(data?: DirectDepositInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DirectDeposit`.
   */
  public static getModelName() {
    return "DirectDeposit";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of DirectDeposit for dynamic purposes.
  **/
  public static factory(data: DirectDepositInterface): DirectDeposit{
    return new DirectDeposit(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'DirectDeposit',
      plural: 'directDeposits',
      path: 'directDeposits',
      idName: 'id',
      properties: {
        "type": {
          name: 'type',
          type: 'string',
          default: 'order'
        },
        "profileId": {
          name: 'profileId',
          type: 'any'
        },
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "fulfillmentDate": {
          name: 'fulfillmentDate',
          type: 'Date'
        },
        "voucherId": {
          name: 'voucherId',
          type: 'any'
        },
        "orderId": {
          name: 'orderId',
          type: 'number'
        },
        "orderProducts": {
          name: 'orderProducts',
          type: 'Array&lt;any&gt;'
        },
        "amount": {
          name: 'amount',
          type: 'number'
        },
        "remarks": {
          name: 'remarks',
          type: 'string'
        },
        "staffRemarks": {
          name: 'staffRemarks',
          type: 'string'
        },
        "images": {
          name: 'images',
          type: 'Array&lt;any&gt;'
        },
        "transactionId": {
          name: 'transactionId',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "transactionData": {
          name: 'transactionData',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        profile: {
          name: 'profile',
          type: 'Profile',
          model: 'Profile',
          relationType: 'belongsTo',
                  keyFrom: 'profileId',
          keyTo: 'id'
        },
        voucher: {
          name: 'voucher',
          type: 'Voucher',
          model: 'Voucher',
          relationType: 'belongsTo',
                  keyFrom: 'voucherId',
          keyTo: 'id'
        },
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'orderId',
          keyTo: 'id'
        },
        transaction: {
          name: 'transaction',
          type: 'Transaction',
          model: 'Transaction',
          relationType: 'belongsTo',
                  keyFrom: 'transactionId',
          keyTo: 'id'
        },
      }
    }
  }
}
