import {Component, Input, OnInit} from '@angular/core';
import {CONTACT} from '../../models/contact.model';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  toggleSingle: boolean = true;
  selectedContact: CONTACT;
  constructor() { }

  ngOnInit(): void {
  }

  singleChatWindow(contact: CONTACT) {
    this.selectedContact = contact;
    this.toggleSingle = false;
  }

  backToContacts(ev: boolean): void {
    this.selectedContact = null;
    this.toggleSingle = true;
  }

}
