<div class="card-header border-0 flex-wrap pb-0">
  <div class="d-flex mb-3">
    <div class="separator"></div>
    <div class="mr-auto">
      <h4 class="text-black fs-20">Recent Order Request</h4>
      <p class="fs-13 mb-0">Latest products ordered</p>
    </div>
  </div>

</div>
<div class="card-body p-0">
  <div class="table-responsive">
    <table class="table order-request">
      <tbody class="loadmore-content" id="orderRequestContent">
      <tr *ngFor = "let product of data">
        <td>
          <div class="media align-items-center">
            <img class="mr-3 " width="87" src="{{product.imageSrc}}" alt="DexignZone">
            <div class="media-body">
              <h5 class="mt-0 mb-2"><a class="text-black" [routerLink]="['/orders', product.orderId]">{{product.name}}</a></h5>
              <p class="mb-0 text-primary">{{product.subCategoryName}}</p>
            </div>
          </div>
        </td>
        <td>
          <h5 class="mb-2 text-black wspace-no">{{product.profileDetails?.displayName}}</h5>
          <p class="mb-0" *ngIf="product.isPickUp">PICKUP</p>
          <p class="mb-0" *ngIf="!product.isPickUp">receiver:{{product.deliveryAddress?.displayName}}
            ({{product.deliveryAddress?.phone}})
        </td>
        <td>
          <div class="d-flex align-items-center justify-content-center">
            <h4 class="mb-0 mr-3 fs-20 text-black d-inline-block">{{product.unitPrice.toFixed(2)}}</h4>
            <p class="mb-0 fs-20 d-inline-block">x{{product.quantity}}</p>
          </div>
        </td>
        <td>
          <div class="d-flex align-items-center">
            <a class="btn btn-primary" href="javascript:void(0);" [routerLink]="['/orders', product.orderId]">View</a>
          </div>
        </td>
      </tr>
      <!-- <div [innerHTML]="itemHtml"></div> -->
      </tbody>
    </table>
    <!-- <div class="card-footer border-0 pt-0 text-center">
        <a href="javascript:void(0);" class="btn btn-outline-primary dz-load-more" id="orderRequest" (click)="addOrderItem()">View More</a>
    </div> -->
  </div>
</div>
