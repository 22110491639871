import {Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID, TemplateRef, ViewChild} from '@angular/core';
import {ModalDismissReasons, NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {isPlatformBrowser} from '@angular/common';
import {Printer} from '../add-edit-printer/add-edit-printer.component';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  closeResult: string;
  modalOpen: Boolean;
  @ViewChild('confirmDialog', {static: true}) ConfirmDialog: TemplateRef<any>;
  @Output() result: EventEmitter<any> = new EventEmitter();

  title: string;
  message: string;

  constructor(public modalService: NgbModal, public activeModal: NgbActiveModal, @Inject(PLATFORM_ID) private platformId: Object,) {}

  ngOnInit(): void {
  }

  openModal(title: string, message: string) {
    this.title = title;
    this.message = message;
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR
      return this.modalService.open(this.ConfirmDialog, {
        size: 'md',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }


}
