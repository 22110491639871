<div class="footer bg-primary mt-auto">
  <div class="copyright">
    <div class="row">
      <div class="col-lg-4">
        TabletID: <b>{{terminalIdentity.tmnHwIdHex}}</b>
      </div>
      <div class="col-lg-4">
        <span class="text-center">Staff Name: {{authService.loggedInUser.value?.user.displayName}}</span>
      </div>
      <div class="col-lg-4">
        <span class="pull-right">{{ipcService.branchInformation.value?.address?.displayName}}</span>
      </div>
    </div>
  </div>
</div>

