import {Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {FirestoreService} from '../../shared/services/firestore/firestore.service';
import {AuthenticationService} from '../../authentication/authentication.service';
import {takeUntil} from 'rxjs/operators';
import {CHAT, CHATMESSAGE} from '../../models/chat';
import {DocumentReference} from '@angular/fire/firestore';
import {CONTACT} from '../../models/contact.model';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.css']
})
export class ConversationComponent implements OnInit, OnDestroy {

  @Input() contact: CONTACT;
  @Output() exitChat: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _unsubscribe: Subject<any>;
  messages: CHATMESSAGE[] = [];
  chatId: string;
  chat: CHAT;
  message: string;
  me: string;
  myself: any;

  constructor(private firestore: FirestoreService, private zone: NgZone, private authService: AuthenticationService) {
  }

  ngOnInit(): void {
    console.log('initi running', this.contact);
    this._unsubscribe = new Subject<any>();
    this.authService.loggedInUser
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(async user => {
        if (user) {
          console.log('logged', user);
          this.myself = user;
          this.me = user.firebaseUser.uid;

          this.firestore.colWithIds$<any>(`chatapp/${user.user.vendorId}/chat`, ref => ref.where('participants', 'array-contains', this.contact.userId))
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(async chats => {

              console.log('found chats', chats);
              if (chats && chats.length > 0) {
                this.chat = chats[0];
                this.chatId = chats[0].id;
              } else {
                console.log('creating new chat ...');
                let chatObject = new CHAT(user.firebaseUser.uid, this.contact.userId); // create the chat if doesnt exist :: let upsert
                console.log('dubioud chat object', chatObject.chat);
                let chatDoc: DocumentReference = await this.firestore.add(`chatapp/${user.user.vendorId}/chat`, chatObject.chat);
                this.chatId = chatDoc.id;
                console.log('chatDocX', chatDoc);
                //this.chat = await this.firestore.doc$<CHAT>(`chatapp/${user.profile.vendorId}/chat/${chatDoc.id}`).toPromise();
                console.log('chatchat', this.chat);
              }


              this.firestore.colWithIds$(`chatapp/${user.user.vendorId}/chat/${this.chatId}/messages`, ref => ref.orderBy('updatedAt', 'asc'))
                .pipe(takeUntil(this._unsubscribe))
                .subscribe(messages => {
                  if (messages) {
                    this.zone.run(() => {
                      this.messages = messages;
                    });
                  }
                });
            });
        }
      });

  }

  async sendMessage(): Promise<any> {
    if (this.message.length > 0) {
      let chatMessage: CHATMESSAGE = new CHATMESSAGE(this.firestore, this.me, this.contact.userId, 'text', this.message);
      await this.firestore.add<CHATMESSAGE>(`chatapp/${this.myself.user.vendorId}/chat/${this.chatId}/messages`, chatMessage.message);
      this.message = '';
    }
  }


  backToContacts(): void {
    this.exitChat.emit(true);
  }


  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

}
