import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AuthenticationService} from '../../authentication/authentication.service';
import {IpcService} from '../services/ipc/ipc.service';
import {AddEditPrinterComponent, Printer} from '../../components/modals/add-edit-printer/add-edit-printer.component';
import {ConfirmDialogComponent} from '../../components/modals/confirm-dialog/confirm-dialog.component';
import {isPlatformBrowser} from '@angular/common';
import {NgxSpinner, NgxSpinnerService} from 'ngx-spinner';
import {LoggerService} from '../services/logger.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  currentMenu: string = 'dashboard';
  tableOrPrinterControls: string = 'tablet';
  toggleChat: boolean = true;

  loggedInUser: BehaviorSubject<any> = new BehaviorSubject(null);


  @ViewChild('addEditPrinter') AddEditPrinter: AddEditPrinterComponent;
  @ViewChild('confirmDialog') ConfirmDialog: ConfirmDialogComponent;

  constructor(private authService: AuthenticationService, public ipcService: IpcService, private spinner: NgxSpinnerService, private logger: LoggerService) {
    this.authService.loggedInUser
      .subscribe(loggedInUser => {
        if (loggedInUser) {
          this.loggedInUser.next(loggedInUser);
        }
      });
  }

  ngOnInit(): void {
    this.ipcService.getTerminalBranch();
  }

  toggleIcon: boolean = true;

  toggleLoveIcon() {
    this.toggleIcon = !this.toggleIcon;
  }

  activeId: number = 1;

  togglechatbar(activeId?: number) {
    this.activeId = activeId || 1;
    this.toggleChat = !this.toggleChat;

  }

  removePrinter(printer: any): void {
    const confirmDialogRef = this.ConfirmDialog.openModal('Remove Printer', `<span>You are removing <span class="text-primary">${printer.name}</span> printer. <br><strong>This action cannot be undone.</strong></span>`);
    confirmDialogRef.result.then((result) => {
      if (result) {
        this.ipcService.removePrinter(printer);
      }
    });
  }

  async updateDevice(): Promise<any> {
    try {
      this.logger.loadingText = 'Updating Local Files and settings. please wait ...';
      await this.spinner.show();
      this.ipcService.updateFiles();
    } catch (e) {
      this.spinner.hide();
      this.logger.displayMessage(e.message, 'Error', 'error');
    }
  }

  logout(): void {
    this.authService.logout();
  }


}
