<div id="main-wrapper" [ngClass]="{'menu-toggle': sharedService.hamburgerClass, '': !sharedService.hamburgerClass}"
     class="show d-flex flex-column min-vh-100">


  <!--<app-nav-header></app-nav-header>
  <app-header></app-header> -->
  <app-navigation></app-navigation>

  <div class="wrapper flex-grow-1">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>


  <!--start modals -->


  <!--end modals -->
</div>

<app-order-summary #orderSummaryDialog></app-order-summary>
