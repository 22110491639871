import firebase from 'firebase';
import FieldValue = firebase.firestore.FieldValue;
import {FirestoreService} from '../shared/services/firestore/firestore.service';
import Timestamp = firebase.firestore.Timestamp;
import * as moment from 'moment-timezone';

export class CHATMESSAGE {
  sender_uid: string;
  receiver_uid: string;
  type: string;
  text: string;
  media?: any;
  sentAt: Date;
  updatedAt?: any;

  constructor(private firestore: FirestoreService, sender_uid: string, receiver_uid: string, type: string, text: string, media?: any) {
    this.sender_uid = sender_uid;
    this.receiver_uid = receiver_uid;
    this.sentAt = new Date();
    this.type = type;
    this.text = text;
    this.media = media || null;
  }

  get message() {
    return {
      sender_uid: this.sender_uid,
      receiver_uid: this.receiver_uid,
      type: this.type,
      text: this.text,
      media: this.media,
      sentAt: this.sentAt
    };
  }


}

export class CHAT {
  id?: any;
  participants: Array<string>;
  messages?: any;
  // messages: Array<CHATMESSAGE>; :: collection  of chat messages ::
  lastMessageAt?: Date;
  constructor(me: string, friend: string) {
    this.participants = [me, friend];
  }

  get chat() {
    return this;
  }
}
