import firebase from 'firebase';
import {FirestoreService} from '../shared/services/firestore/firestore.service';
import FieldValue = firebase.firestore.FieldValue;

export class CONTACT {
  displayName: string;
  userId: string;
  lbUserId: string;
  profileId: string;
  online: boolean;
  branchId: string;
  branchName: string;
  lastSeen: Date;
  status: number;
  updatedAt?: any;

  constructor(private firestore: FirestoreService, private loggedInUser: any, private branchInformation: any) {
    this.lastSeen = new Date();
    this.online = true;
    this.displayName = this.loggedInUser.profile.displayName;
    this.lbUserId = this.loggedInUser.user.id;
    this.profileId = this.loggedInUser.profile.id;
    this.userId = this.loggedInUser.firebaseUser.uid;
    this.branchId = this.branchInformation.id;
    this.branchName = this.branchInformation.address?.displayName;
    this.status = 0;
  }

  get contact() {
    return {
      displayName: this.displayName,
      userId: this.userId,
      lbUserId: this.lbUserId,
      profileId: this.profileId,
      online: this.online,
      branchId: this.branchId,
      branchName: this.branchName,
      lastSeen: this.lastSeen,
      status: this.status
    }
  }
}
