import {AfterViewInit, Component} from '@angular/core';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {Router} from '@angular/router';
import {LoggerService} from './shared/services/logger.service';
import {IpcService} from './shared/services/ipc/ipc.service';
import {DataService} from './shared/services/data/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'kobobranch';

  constructor(private loader: LoadingBarService,
              public _logger: LoggerService,
              private dataService: DataService,
              private ipcService: IpcService,
              private router: Router) {
    //this.router.navigate(['/dashboard']);

  }

  ngAfterViewInit() {
    this.dataService.subscribeToData();
    var kobo = {
      typography: 'poppins',
      version: 'light',
      layout: 'horizontal',
      primary: 'color_5',
      headerBg: 'color_1',
      navheaderBg: 'color_1',
      sidebarBg: 'color_1',
      sidebarStyle: 'full',
      sidebarPosition: 'static',
      headerPosition: 'static',
      containerLayout: 'full',
    };

    var themeVar = eval('kobo');
    document.body.setAttribute('data-typography', themeVar.typography);
    document.body.setAttribute('data-theme-version', themeVar.version);
    document.body.setAttribute('data-layout', themeVar.layout);
    document.body.setAttribute('data-primary', themeVar.primary);
    document.body.setAttribute('data-headerbg', themeVar.headerBg);
    document.body.setAttribute('data-nav-headerbg', themeVar.navheaderBg);
    document.body.setAttribute('data-sibebarbg', themeVar.sidebarBg);
    document.body.setAttribute('data-sidebar-style', themeVar.sidebarStyle);
    document.body.setAttribute('data-sidebar-position', themeVar.sidebarPosition);
    document.body.setAttribute('data-header-position', themeVar.headerPosition);
    document.body.setAttribute('data-container', themeVar.containerLayout);
    document.body.setAttribute('direction', 'ltr');

    document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
    document.getElementsByTagName('html')[0].setAttribute('class', 'ltr');
  }
}
