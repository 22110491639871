import {Injectable} from '@angular/core';
import {FirestoreService} from '../../shared/services/firestore/firestore.service';
import {CONTACT} from '../../models/contact.model';
import {AuthenticationService} from '../../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor() {
  }
}
