import {Injectable, NgZone, ViewChild} from '@angular/core';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {LoggerService} from '../logger.service';
import {Order, OrderApi, RealTime} from '../../sdk';
import {BehaviorSubject} from 'rxjs';
import {EventQueueService} from '../event-queue/event-queue.service';
import {AppEvent} from '../event-queue/app.event.class';
import {AppEventType} from '../event-queue/app.event.type';
import {IpcService} from '../ipc/ipc.service';
import * as moment from 'moment-timezone';
import {ProductInventory} from '../../../models/product-inventory';
import {InternalApiService} from '../internalapi/internalapi.service';
import {ProductsListService} from '../../../products/products-list/products-list.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private authService: AuthenticationService,
              private eventQueue: EventQueueService,
              private zone: NgZone,
              private ipcService: IpcService,
              private orderApi: OrderApi,
              private realtimeService: RealTime,
              private productsService: ProductsListService,
              private internalApi: InternalApiService,
              private logger: LoggerService) {
  }

  connectToRealtime(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.realtimeService.connection.isConnected() && this.authService.loggedInUser.value) {
        this.realtimeService.connection.connect(this.authService.loggedInUser.value.token);
        resolve(true);
        return;
      }
      resolve(true);
    });
  }


  receivedMessages: Array<any> = [];

  subscribeToData(): void {
    this.logger.log('subscribing to data ... ');
    this.ipcService.branchInformation.subscribe(branch => {
      if (branch && branch.id) {
        this.connectToRealtime()
          .then(connected => {
            this.realtimeService.onReady()
              .subscribe((ready) => {
                if (ready) {

                  this.realtimeService.IO.on(`/stockUpdate/${branch.id}`)
                    .subscribe(async (data: any) => {
                      if (data) { //data :: {stockBalance: currentStockBalance, branchId: branchId, productId: productId, date: date}
                        console.log('stockUpdate', data);
                        let dateToday = moment().tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD');
                        if (dateToday == data.date) { // valid data ::
                          let productInventory: ProductInventory = new ProductInventory(data.data.productId, this.authService.loggedInUser.value?.user);
                          productInventory.currentstock = data.data.stockBalance;
                          let res = await this.internalApi.updateSingleProductCurrentInventory(productInventory);
                          let products: any = this.productsService.onProductsChanged.value;
                          if (products && products.length > 0) {
                            let index = products.findIndex(x => x.id === productInventory.productid);
                            if(index > -1) {
                              let product = products[index];
                              product.dailystocklimit = productInventory.currentstock;
                              products[index] = product;
                              this.productsService.onProductsChanged.next(products);
                            }
                          }
                        }
                        return Promise.resolve();
                      }
                    });


                  //listen to orders change events ::
                  this.realtimeService.IO.on(`/branch/${branch.id}/POST`)
                    .subscribe((data: any) => {
                      if (data) {
                        if (this.receivedMessages.findIndex(x => x.uuid === data.uuid) < 0) {
                          this.receivedMessages.push(data);
                          if (this.receivedMessages.length > 20) {
                            this.receivedMessages = [data];
                          }
                          this.logger.log(data);
                          switch (data.action) {
                            case 'order-created':
                              return this.dealOrderCreated(data);
                              break;
                            case 'order-updated':
                              return this.dealOrderUpdated(data);
                              break;
                            default:
                              this.logger.log(data, 'unknown data received over socket connection.');
                              break;
                          }
                        }
                      }
                    }, e => {
                      this.logger.log(e);
                    });

                }
              }, e => {
                console.log(e);
              });
          }).catch(e => {
          this.logger.log(e);
        });
      }
    });
  }

  orderCreated: BehaviorSubject<any> = new BehaviorSubject(null);

  async dealOrderCreated(data): Promise<any> {
    try {
      let orderResponse: any = await this.orderApi.retrieveOrders({
        draw: 1,
        order: [],
        currentPage: 1,
        //start: 0,
        length: 1,
        search: {value: '', regex: false},
        extendedFilter: {
          orderId: data.id,
          dateInFocusFilter: 'fulfillmentdate'
        }
      }).toPromise();

      orderResponse.data = orderResponse.data.map(x => {
        return {
          ...x,
          status: `<span class="badge text-white badge-${x.orderupdates[x.orderupdates.length - 1].class}">${x.orderupdates[x.orderupdates.length - 1].state}</span>`
        };
      });

      if (orderResponse.data.length > 0) {
        this.orderCreated.next(orderResponse.data[0]);
        this.eventQueue.dispatch(new AppEvent(AppEventType.NewOrderReceived, this.orderCreated.value));
      }

      return Promise.resolve();

    } catch (e) {
      console.error('dealOrderCreate', e.message);
      return Promise.reject();
    }
  }

  orderUpdated: BehaviorSubject<any> = new BehaviorSubject(null);

  async dealOrderUpdated(data): Promise<any> {
    try {
      let orderResponse: any = await this.orderApi.retrieveOrders({
        draw: 1,
        order: [],
        currentPage: 1,
        //start: 0,
        length: 1,
        search: {value: '', regex: false},
        extendedFilter: {
          orderId: data.id,
          dateInFocusFilter: 'fulfillmentdate'
        }
      }).toPromise();

      orderResponse.data = orderResponse.data.map(x => {
        return {
          ...x,
          status: `<span class="badge text-white badge-${x.orderupdates[x.orderupdates.length - 1].class}">${x.orderupdates[x.orderupdates.length - 1].state}</span>`
        };
      });


      if (orderResponse.data.length > 0) {
        this.orderUpdated.next(orderResponse.data[0]);
        this.eventQueue.dispatch(new AppEvent(AppEventType.OrderUpdated, this.orderUpdated.value));
      }

      return Promise.resolve();

    } catch (e) {
      console.error('dealOrderUpdate', e.message);
      return Promise.reject();
    }
  }


  async loadOrder(id: any): Promise<any> {
    try {
      console.log('xxd', id);
      let order: Order = await this.orderApi.findOne<Order>({where: {id: id}}).toPromise();
      if (!(order && order.id)) {
        return Promise.reject('Order Not Found!');
      }
      return Promise.resolve(order);
    } catch (e) {
      this.logger.displayMessage(e.message, 'Error', 'error');
      return Promise.reject(e);
    }
  }
}
