<ng-template #orderSummaryDialog let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">New Order Alert!</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body fs-12" *ngIf="order">
    <div class="row">
      <div class="card col-md-12">
        <div class="card-body p-0">
          <div class="table-responsive order-list card-table">
            <table class="table items-table table-responsive-md">
              <tbody>
              <tr class="bg-primary">
                <th class="text-black font-w500 fs-12">Items</th>
                <th style="width:10%;" class="text-black font-w500 fs-12">Qty</th>
                <th style="width:10%;" class="text-black font-w500 fs-12">Unit Cost</th>
                <th style="width:10%;" class="text-black font-w500 fs-12">Discount</th>
                <th class="text-black font-w500 fs-12 ">Total Amount</th>
              </tr>
              <tr *ngFor="let item of order.orderProducts;let i=index;">
                <td>
                  <div class="media">
                    <a><img class="mr-3 img-fluid rounded" [src]="item.images[0].src" width="87px" height="87px"
                            alt="DexignZone"></a>
                    <div class="media-body">
                      <small class="mt-0 mb-1 font-w500"><a class="text-primary"
                                                            href="javascript:void(0);">{{item.subCategoryName}}</a></small>
                      <h6 class="mt-0 mb-2 mb-sm-3"><a
                        class="text-black">{{item.name + (item.selectedAttribute?.name ? ' (' + item.selectedAttribute?.name + ')' : '')}}</a>
                      </h6>
                      <div class="star-review d-flex fs-12">
                        <i class="fa fa-star text-orange"></i>
                        <i class="fa fa-star text-orange"></i>
                        <i class="fa fa-star text-orange"></i>
                        <i class="fa fa-star text-orange"></i>
                        <i class="fa fa-star text-gray"></i>
                        <span class="ml-3 text-dark">(0 Reviews)</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <h6 class="my-0  font-w600">{{item.quantity}}x</h6>
                </td>
                <td>
                  <h6
                    class="my-0  font-w600 text-center">{{(item.salePrice || (item.unitPrice) + (item.selectedAttribute?.amount > 0 ? item.selectedAttribute?.amount : 0)).toFixed(2)}}</h6>
                </td>
                <td>
                  <h6 class="my-0  font-w600 text-center">{{item.totalDiscount.toFixed(2)}}</h6>
                </td>
                <td class="d-md-none d-lg-table-cell">
                  <h6 class="my-0  font-w600 text-center">{{item.totalAmount.toFixed(2)}}</h6>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">OK</button>
    <button type="button" class="btn btn-danger" (click)="modal.close('Confirm click')" [routerLink]="['/orders', order.id]">View Details</button>
  </div>
</ng-template>
