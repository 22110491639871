import {Component, OnInit} from '@angular/core';
import {IpcService} from '../services/ipc/ipc.service';
import {AuthenticationService} from '../../authentication/authentication.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  terminalIdentity: any = {tmnHwId: 0, tmnHwIdHex: 'NOT SET'};

  constructor(public ipcService: IpcService,
              public authService: AuthenticationService) {
    this.ipcService.terminalIdentity.subscribe(terminalIdentity => {
      if (terminalIdentity) {
        this.terminalIdentity = terminalIdentity;
      }
    });
  }

  ngOnInit(): void {

  }

}
