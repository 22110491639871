import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import * as http from "http";
import {LoggerService} from "../logger.service";
import {ProductInventory} from "../../../models/product-inventory";

@Injectable({
  providedIn: 'root'
})
export class InternalApiService {

  constructor(private http: HttpClient, private logger: LoggerService) {
  }

  async getBackendConfig(): Promise<any> {
    try {
      let res: any = await this.http.get('http://localhost:4280/backendConfig').toPromise();
      this.logger.log('getBackendConfig', res);
      return Promise.resolve(res);
    } catch (e) {
      this.logger.displayMessage(e.message, 'Error', 'error');
      return Promise.reject(e);
    }
  }

  async dbQuery(query: string): Promise<any> {
    try {
      let res: any = await this.http.post('http://localhost:4280/dbQuery', {query: query}).toPromise();
      this.logger.log('dbQueryRes', res);
      return Promise.resolve(res);
    } catch (e) {
      this.logger.displayMessage(e.message, 'Error', 'error');
      return Promise.reject(e);
    }
  }

  async updateCurrentInventory(productInventory: ProductInventory): Promise<any> {
    try {
      let res: any = await this.http.post('http://localhost:4280/currentProductInventory', productInventory).toPromise();
      this.logger.log('updateCurrentInventory', res);
      return Promise.resolve(res);
    } catch (e) {
      this.logger.displayMessage(e.message, 'Error', 'error');
      return Promise.reject(e);
    }
  }

  async updateSingleProductCurrentInventory(productInventory: ProductInventory): Promise<any> {
    try {
      let res: any = await this.http.post('http://localhost:4280/currentSingleProductInventory', productInventory).toPromise();
      this.logger.log('updateSingleProductCurrentInventory', res);
      return Promise.resolve(res);
    } catch (e) {
      this.logger.displayMessage(e.message, 'Error', 'error');
      return Promise.reject(e);
    }
  }

  async updateProductInventory(productInventory: ProductInventory): Promise<any> {
    try {
      let res: any = await this.http.post('http://localhost:4280/productInventory', productInventory).toPromise();
      this.logger.log('productInventory', res);
      return Promise.resolve(res);
    } catch (e) {
      this.logger.displayMessage(e.message, 'Error', 'error');
      return Promise.reject(e);
    }
  }

  async queryProducts({where, orderBy, skip, limit}): Promise<any> {
    try {
      let res: any = await this.http.post('http://localhost:4280/queryProducts', {where, orderBy, skip, limit}).toPromise();
      this.logger.log('queryProductsAsync', res);
      return Promise.resolve(res);
    } catch (e) {
      this.logger.displayMessage(e.message, 'Error', 'error');
      return Promise.reject(e);
    }
  }

}
