import {Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID, TemplateRef, ViewChild} from '@angular/core';
import {ModalDismissReasons, NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {isPlatformBrowser} from '@angular/common';
import {Order, OrderApi} from '../../../shared/sdk';
import {LoggerService} from '../../../shared/services/logger.service';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.css']
})
export class OrderSummaryComponent implements OnInit {

  closeResult: string;
  modalOpen: Boolean;
  @ViewChild('orderSummaryDialog', {static: true}) OrderSummaryDialog: TemplateRef<any>;
  @Output() result: EventEmitter<any> = new EventEmitter();
  order: Order;

  constructor(public modalService: NgbModal, public activeModal: NgbActiveModal, @Inject(PLATFORM_ID) private platformId: Object, private logger: LoggerService, private orderApi: OrderApi) {
  }


  ngOnInit(): void {
  }

  async openModal(order: Order): Promise<any> {
    this.order = order;
    console.log('opening order summary modal ::', order);
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR
      return this.modalService.open(this.OrderSummaryDialog, {
        size: 'lg',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
      });
    }
    return Promise.resolve();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

}
