import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Branch, BranchApi, LoopBackConfig, Product, ProductApi, UserApi} from '../sdk';
import {AuthenticationService} from "../../authentication/authentication.service";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {InternalApiService} from './internalapi/internalapi.service';
import {IpcService} from './ipc/ipc.service';

  class APPCONFIG {
  tnc: string;
  streetTypes: Array<{ name: string }>;
  states: Array<{ name: string }>;
  businessTypes: Array<{ name: string }>;
  clientUrl: string;
  leadTimes: Array<{ duration: string }>;
  uoms: Array<{ name: string }>;
  voucherOptions: { occasions: Array<{ code: string, name: string }>, types: Array<{ code: string, name: string }>, categories: Array<{ code: string, name: string }> };
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  appConfig: APPCONFIG;
  branches: BehaviorSubject<Array<Branch>> = new BehaviorSubject<Array<Branch>>([]);
  youtubeVideos: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  _appConfig: any;
  constructor(private http: HttpClient,
              private _userApi: UserApi,
              private branchApi: BranchApi,
              private _authService: AuthenticationService,
              private _productApi: ProductApi,
              private ipcService: IpcService,
              private _internalApiService: InternalApiService,
              private router: Router) {

    this._authService.loggedInUser.subscribe(loggedInUser => {
      if (loggedInUser?.user?.vendorId) {
        /*this.youtubeService.getVideosForChanel(loggedInUser.user.vendor.youtubeChannelId, 15)
          .subscribe(res => {
            if (res) {
              console.log(res['items']);
              this.youtubeVideos.next(res['items']);
            }
          }) */
        this.loadMerchantProducts();
      }
    });
  }

  async initialize(): Promise<any> {
    LoopBackConfig.setBaseURL(environment.loopback.backendUrl);
    LoopBackConfig.setApiVersion('api');
    await this.loadBranches();
    let selectedBranch: string = localStorage.getItem('selectedBranch');
    if(selectedBranch && selectedBranch.length > 0) {
      let branch: Branch = this.branches.value.find(x => x.id === selectedBranch);
      if(branch?.id) {
        this.ipcService.branchInformation.next(branch);
      }
    }
    return new Promise<any>(async (resolve, reject) => {
      //this._appConfig = await this._internalApiService.getBackendConfig();

      Promise.all([
        this.checkAuthStatus(),
        this.initializeAppConstants()
      ]).then(
        () => {
          resolve();
        }, reject);
    });
  }

  async loadBranches(): Promise<void> {
    try {
      let branches: Array<Branch> = await this.branchApi.find({where: {status: 0}}).toPromise() as Array<Branch>;
      console.log('branches', branches);
      this.branches.next(branches);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async checkAuthStatus(): Promise<any> {
    console.log('checkAuthStatus', 'started...' + this._userApi.isAuthenticated());
    // check loggedIn status ::
    if (this._userApi.isAuthenticated()) {
      console.log('checkAuthStatus', 'checking auth');
      try {
        //await this._userApi.getCurrent().toPromise();
        let au = await this._authService.checkUserAuthStatus();
        console.log('au has been returned!');
        return Promise.resolve(au);
      } catch (e) {
        console.log('checkAuthStatus', 'here we are')
        console.log('checkAuthStatus', 'status', e);
        if (e.statusCode === 401) {// is not authenticated! log them out::
          await this._userApi.logout().toPromise().catch(console.log);
          this.router.navigate(['auth/login']);
          return Promise.resolve();
        }
        return Promise.reject();
      }
    } else {
      console.log('checkAuthStatus', 'Not Authenticated!');
      //await this._userApi.logout().toPromise().catch(console.log);
      await this._authService.logout();
      //await this.router.navigate(['auth/login']);
      return Promise.resolve();
    }
  }


  async initializeAppConstants(): Promise<any> {
    this.http.get(`${environment.loopback.apiUrl}/systemConfiguration`).subscribe((appConfig: APPCONFIG) => {
      if (appConfig) {
        console.log(appConfig);
        this.appConfig = appConfig;
      }
      return Promise.resolve();
    }, e => {
      console.log(e.message);
      return Promise.reject(e);
    });
  }

  products: Array<Product> = [];

  async loadMerchantProducts(): Promise<any> {
    let products = await this._productApi.find<Product>({
      where: {and: [{vendorId: this._authService.loggedInUser.value?.user?.vendor?.id}, {status: 0}, {isActive: true}, {inventory: {gt: 0}}]},
      order: 'name ASC'
    }).toPromise();
    this.products = [...products];
    return Promise.resolve(products);
  }

  async updateTerminalLastSeen(): Promise<void> {

  }
}
