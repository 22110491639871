<div class="card-header chat-list-header text-center" *ngIf="contact">
  <a href="javascript:void(0)" class="dz-chat-history-back"  (click)= "backToContacts()">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><polygon points="0 0 24 0 24 24 0 24"/><rect fill="#000000" opacity="0.3" transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000) " x="14" y="7" width="2" height="10" rx="1"/><path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) "/></g></svg>
  </a>
  <div>
    <h6 class="mb-1">{{contact.displayName}}</h6>
    <p class="mb-0 text-success">{{contact.online ? 'Online' : (contact.updatedAt.seconds * 1000 | date: 'medium')}}</p>
  </div>

</div>
<div class="card-body msg_card_body" [perfectScrollbar]>
  <div class="d-flex justify-content-start mb-4" *ngFor="let message of messages;let i = index;" [ngClass]="{'justify-content-start': message.sender_uid === me , 'justify-content-end': message.sender_uid !== me }">
    <div [ngClass]="{'msg_cotainer': message.sender_uid === me , 'msg_cotainer_send': message.sender_uid !== me }">
      {{message.text}}
      <span class="msg_time">{{message.updatedAt.seconds * 1000 | date : 'medium'}}</span>
    </div>
  </div>
</div>

<div class="card-footer type_msg">
  <div class="input-group">
    <textarea class="form-control" placeholder="Type your message..." [(ngModel)]="message"></textarea>
    <div class="input-group-append">
      <button type="button" class="btn btn-primary" (click)="sendMessage();"><i class="fa fa-location-arrow"></i></button>
    </div>
  </div>
</div>
