<ul class="contacts">
  <li class="name-first-letter">All Contacts</li>
  <li class="active dz-chat-user" (click)= "openConversation(contact)" *ngFor="let contact of contacts | async">
    <div class="d-flex bd-highlight">
      <div class="img_cont primary">
        {{getInitials(contact)}}
        <!--<img src="assets/images/avatar/1.jpg" class="rounded-circle user_img" alt=""/> -->
        <span class="online_icon" [ngClass]="{'offline': !contact.online }"></span>
      </div>
      <div class="user_info">
        <span>{{contact.displayName}}</span>
        <p>{{contact.online ? 'Online' : ('last seen on ' + (contact.updatedAt.seconds * 1000 | date : 'medium'))}}</p>
      </div>
    </div>
  </li>
</ul>

