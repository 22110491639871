import {SIGNATURE} from "./signature.model";
import {Product} from '../shared/sdk';

export class ProductInventory {
  productid: string;
  leadtime: string;
  dailystocklimit: number;
  isoutofstock: boolean;
  updatedby: SIGNATURE;

  currentstock: number;

  attributestock: Array<{id: number, name: string, currentstock: number}> = [];

  attributestocknotset?: boolean;

  constructor(product: Product, user) {
    let signature = new SIGNATURE(user);
    this.productid = product.id;
    this.isoutofstock = false;
    this.leadtime = 'none';
    this.dailystocklimit = 100;
    this.attributestock = [];
    this.updatedby = signature.signature;

    if(product?.attributes && product.attributes.length > 0) {
      this.attributestock = product.attributes.map(attribute => {
        return {
          id: attribute.id,
          name: attribute.name,
          currentstock: 0
        }
      });
    }
  }
}
