import {Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild} from '@angular/core';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {isPlatformBrowser} from '@angular/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {BehaviorSubject} from 'rxjs';
import {IpcService} from '../../../shared/services/ipc/ipc.service';
import {take} from 'rxjs/operators';
import {LoggerService} from '../../../shared/services/logger.service';
import {AuthenticationService} from '../../../authentication/authentication.service';

export interface Printer {
  id: number,
  name: string;
  ipaddress: string;
  iscashier: boolean;
  iskitchen: boolean;
  addedby: string;
  interface: string;
  tmnhwid: number;
}

@Component({
  selector: 'app-add-edit-printer',
  templateUrl: './add-edit-printer.component.html',
  styleUrls: ['./add-edit-printer.component.css']
})
export class AddEditPrinterComponent implements OnInit {

  @ViewChild('addEditPrinter', {static: false}) AddEditPrinter: TemplateRef<any>;

  public modalOpen: boolean = false;
  closeResult: string;

  printer: Printer;
  printerForm: FormGroup;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private modalService: NgbModal, private fb: FormBuilder, private spinner: NgxSpinnerService, private authService: AuthenticationService, private ipcService: IpcService, private logger: LoggerService) {
    this.ipcService.on('printer.updateResponse', async (event, args) => {
      await this.spinner.hide();
      this.logger.displayMessage(args.message, '', args.success ? 'success' : 'error');
    });
  }

  ngOnInit(): void {
  }

  buildForm(): void {
    this.printerForm = this.fb.group({
      id: [{value: this.printer?.id, disabled: true}],
      name: [this.printer?.name || '', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      ipaddress: [this.printer?.ipaddress || '', [Validators.required, Validators.pattern('/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$')]],
      interface: [this.printer?.interface || 'usb', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      tmnhwidhex: [this.printer?.tmnhwid ? Number(this.printer?.tmnhwid).toString(16).toUpperCase() : this.ipcService.terminalIdentity.value.tmnHwIdHex, [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      iscashier: [this.printer?.iscashier],
      iskitchen: [this.printer?.iskitchen],
    });
  }

  openModal(printer?: Printer, action?: string) {
    this.modalOpen = true;
    this.printer = printer;
    this.buildForm();
    if (isPlatformBrowser(this.platformId)) { // For SSR
      this.modalService.open(this.AddEditPrinter, {
        size: 'md',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
      }).result.then((result) => {
        `Result ${result}`;
        if (result === 'Save click') {
          this.submit();
        }
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  async submit(): Promise<any> {
    await this.spinner.show();
    let printerData = this.printerForm.getRawValue();
    console.log(printerData);

    if (printerData.interface === 'usb' && !(printerData.tmnhwidhex && printerData.tmnhwidhex.length > 4)) {
      this.logger.displayMessage('For USB Interface, You mush specify the Terminal ID.');
      return;
    }

    if (printerData.tmnhwidhex && printerData.tmnhwidhex.length > 0) {
      printerData.tmnhwid = parseInt(printerData.tmnhwidhex, 16);
    }

    if (printerData.interface === 'ethernet' && !(printerData.ipaddress && printerData.ipaddress.length > 4)) {
      this.logger.displayMessage('For Ethernet Interface, You mush specify the IP Address.');
      return;
    }

    let printerDataClean: Printer = {
      id: printerData.id,
      name: printerData.name,
      ipaddress: printerData.ipaddress,
      tmnhwid: printerData.tmnhwid,
      iscashier: printerData.iscashier ? true : false,
      iskitchen: printerData.iskitchen ? true : false,
      interface: printerData.interface,
      addedby: this.authService.loggedInUser.value.profile.displayName
    }

    this.ipcService.updatePrinter(printerDataClean);
  }


  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

}
