import {Injectable, NgZone} from '@angular/core';
import {IpcRenderer} from 'electron';
import {LoggerService} from '../logger.service';
import {FirestoreService} from '../firestore/firestore.service';
import {BehaviorSubject} from 'rxjs';
import {Branch, TerminalApi} from '../../sdk';
import {environment} from '../../../../environments/environment';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class IpcService {

  private ipcRenderer: IpcRenderer | undefined;
  public isElectronApp: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public terminalIdentity: BehaviorSubject<any> = new BehaviorSubject<any>({tmnHwIdHex: 'UNREGISTERED DEVICE', tmnHwId: 0});
  public productsQueryResponse: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public subcategoriesQueryResponse: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public wifiInformation: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public branchInformation: BehaviorSubject<Branch> = new BehaviorSubject<any>(null);
  public activePrinters: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public systemInformation: BehaviorSubject<any> = new BehaviorSubject<any>({
    systemVersion: 'unknown os',
    startedAt: 0,
    cpu: 0,
    memUsage: {residentSet: 0, private: 0, shared: 0}, // our app's usage ::
    memory: {total: 0, free: 0},
  });

  public appConfig: BehaviorSubject<any> = new BehaviorSubject(null);

  appVersion: string;


  constructor(private logger: LoggerService,
              public zone: NgZone,
              private spinner: NgxSpinnerService,
              private _terminalApi: TerminalApi,
              private firestoreService: FirestoreService) {
    this.appVersion = environment.appVersion;

    if ((<any> window).require && false) {
      try {
        this.ipcRenderer = (<any> window).require('electron').ipcRenderer;
        this.isElectronApp.next(true);
        this.logger.log('IPC SERVICE RUNNING!');
        this.getTerminalIdentity();
        this.fetchPrinters();
        this.connectPrinters();
        this.fetchAppConfig();
        // start ipc listeners ::
        this.ipcRenderer.on('message.service', (event, args) => {
          this.logger.displayMessage(args.message, args.title, args.type);
          if(args.type === 'error') {
            this.spinner.hide();
          }
        });

        this.ipcRenderer.on('loading.spinner', (event, args) => {
          this.zone.run(() => {
            this.logger.loadingText = args.message;
          });
        });

        this.ipcRenderer.on('system.info', (event, args) => {
          this.zone.run(() => {
            args.cpu.percentCPUUsage = Math.ceil(args.cpu.percentCPUUsage);
            args.memory.total = Math.ceil(Number(args.memory.total) / 1024);
            args.memory.free = Math.ceil(Number(args.memory.free) / 1024);
            args.memUsage.residentSet = Math.ceil(Number(args.memUsage.residentSet || args.memUsage.private) / 1024);
            this.systemInformation.next(args);
          });
        });

        this.ipcRenderer.on('config.update', (event, args) => {
          this.zone.run(() => {
            this.appConfig.next(args.data);
            console.log('config.update', this.appConfig.value);
          });
        });

        this.ipcRenderer.on('terminal.updateTerminalResponse', (event, args) => {
          this.spinner.hide();
          if (args.success) {
            this.logger.displayMessage(args.message || 'Device has been updated.', 'Success', 'success');
            let filter: any = {
              where: '1 = 1',
              orderBy: 'ordering ASC',
              limit: 20,
              skip: 0,
              productsCount: 0,
              currentPage: 1
            };
            this.queryProducts({
              ...filter,
              where: filter.where + ` AND isactive = true AND lower(concat(a.name, a.subcategoryname, b.leadtime, a.code)) like '%%' `
            });
          } else {
            this.logger.displayMessage(args.message || 'Device Update Failed', 'Error', 'success');
          }
        });

        this.ipcRenderer.on('printerService.response', (event, args) => {
          this.logger.log(args);
        });


        this.ipcRenderer.on('printers.updated', (event, args) => {
          //this.logger.log(args);
          let activePrinters = this.activePrinters.value || [];
          if (activePrinters.length > 0) {
            //console.log('activePrinters', activePrinters);
            activePrinters = activePrinters.map(x => {
              if (x.connected) {
                x.connected = false;
              }
              return x;
            });
            for (let i = 0; i < args.length; i++) {
              let index = activePrinters.findIndex(x => x.id === args[i].id);
              if (index > -1) {
                activePrinters[index]['connected'] = true;
              }
            }
            this.zone.run(() => {
              this.activePrinters.next(activePrinters);
            });

          }
        });

        this.ipcRenderer.on('printers.queryResponse', (event, args) => {
          //this.logger.log(args);
          this.zone.run(() => {
            this.activePrinters.next(args);
          });
        });


        this.ipcRenderer.on('terminal.updateBranchResponse', (event, args) => {
          //console.log('updateBranchResponse', args);
          if (args.success) {
            this.zone.run(() => {
              this.branchInformation.next(args.data);
            });
          } else {
            this.logger.displayMessage(args.message, 'Error', 'error');
          }
        });

        this.ipcRenderer.on('wifi.updatedResponse', (event, args) => {
          //this.logger.log(args);
          this.zone.run(() => {
            this.wifiInformation.next(args);
          });
        });

        // collection the temrinal ID response ::
        this.ipcRenderer.on('terminal.terminalIdResponse', (event, args) => {
          //console.log('terminal.terminalIdResponse', args);
          if (args.success) {
            this.zone.run(() => {
              this.terminalIdentity.next(args.data);
            });
          } else {
            this.logger.displayMessage(args.message, 'Error', 'error');
          }
        });

        this.ipcRenderer.on('products.queryResponse', (event, args) => {
          if (args.success) {
            this.productsQueryResponse.next(args.data);
          } else {
            this.logger.displayMessage(args.message, 'Error', 'error');
          }

        });

        this.ipcRenderer.on('products.subcategories.queryResponse', (event, args) => {
          if (args.success) {
            console.log('products.subcategories.queryResponse', args);
            this.subcategoriesQueryResponse.next(args.data);
          } else {
            this.logger.displayMessage(args.message, 'Error', 'error');
          }

        });

      } catch (error) {
        this.logger.log(error);
        throw error;
      }
    } else {
      console.warn('Could not load electron ipc');
    }
  }

  public on(channel: string, listener: any): void {
    if (!this.isElectronApp.value) {
      return;
    }
    console.log('listening to ' + channel);
    this.ipcRenderer.on(channel, listener);
  }

  public runDbQuery(query): void {

  }

  public saveTokenToElectron(token: string): void {
    if (this.isElectronApp.value) {
      console.log('saving token');
      this.ipcRenderer.send('token.save', {token: token});
    }
  }

  public deleteTokenFromElectron(): void {
    if (this.isElectronApp.value) {
      console.log('removing token');
      this.ipcRenderer.send('token.remove', {});
    }
  }

  public updateFiles(): void {
    if (this.isElectronApp.value) {
      console.log('updating device');
      this.ipcRenderer.send('terminal.updateTerminal', {});
      this.terminalHeartbeat();
    }
  }

  public queryProducts({where, orderBy, skip, limit}): void {
    if (this.isElectronApp.value) {
      console.log('querying products');
      this.ipcRenderer.send('products.query', {where: where, orderBy: orderBy, skip: skip, limit: limit});
    }
  }

  public querySubcategories({where, orderBy, skip, limit}): void {
    if (this.isElectronApp.value) {
      console.log('querying subcategories');
      this.ipcRenderer.send('products.subcategories.query', {where: where, orderBy: orderBy, skip: skip, limit: limit});
    }
  }

  terminalShowDetails(): void {
    if (this.isElectronApp.value) {
      this.ipcRenderer.send('terminal.showDetails', {});
    }
  }

  getTerminalIdentity(): void {
    if (this.isElectronApp.value) {
      console.log('fetching terminal identity data');
      this.ipcRenderer.send('terminal.terminalId', {})
    }
  }

  getTerminalBranch(): void {
    if (this.isElectronApp.value) {
      this.ipcRenderer.send('terminal.updateBranch', {});
    }
  }

  printTestPage(printerId): void {
    if (this.isElectronApp.value) {
      this.ipcRenderer.send('printService.printTestPage', {printerId: printerId});
    }
  }

  openCashDrawer(printerId): void {
    if (this.isElectronApp.value) {
      this.ipcRenderer.send('printService.openCashDrawer', {printerId: printerId});
    }
  }

  connectPrinters(): void {
    if (this.isElectronApp.value) {
      this.ipcRenderer.send('printers.connect', []);
    }
  }

  updatePrinter(printer: any): void {
    if (this.isElectronApp.value) {
      this.ipcRenderer.send('printer.update', {data: printer});
    }
  }

  removePrinter(printer: any): void {
    if (this.isElectronApp.value) {
      this.ipcRenderer.send('printer.remove', {data: printer});
    }
  }

  fetchPrinters(): void {
    if (this.isElectronApp.value) {
      this.ipcRenderer.send('printers.query', {});
    }
  }


  fetchAppConfig(): void {
    if (this.isElectronApp.value) {
      this.ipcRenderer.send('config.query', {});
    }
  }

  printOrder(orderDetails: any, isKitchen?: boolean): void {
    if (this.isElectronApp.value) {
      this.ipcRenderer.send(isKitchen ? 'printer.printKitchenSlip' : 'printer.printOrderSlip', orderDetails);
    }
  }

  beep(): void {
    if (this.isElectronApp.value) {
      this.ipcRenderer.send('printer.beep', {tmnHwIdHex: this.terminalIdentity.value.tmnHwIdHex});
    }
  }

  async terminalHeartbeat(): Promise<void> {
    let heartbeat = await this._terminalApi.terminalHeartbeat({tmnHwId: this.terminalIdentity.value?.tmnHwId, firmwareVersion: `${this.appVersion}`}).toPromise();
    console.log('heartbeat3', heartbeat);
  }

}
