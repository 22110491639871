/* tslint:disable */
import {
  Vendor,
  Profile
} from '../index';

declare var Object: any;
export interface UserInterface {
  "ic"?: string;
  "dob"?: string;
  "vendorId"?: any;
  "profileId"?: any;
  "facebookId"?: string;
  "googleId"?: string;
  "phoneId"?: string;
  "appleId"?: string;
  "telegramClientId"?: string;
  "plainEmail"?: string;
  "realm"?: string;
  "username"?: string;
  "email": string;
  "emailVerified"?: boolean;
  "id"?: any;
  "role"?: string;
  "verificationCode"?: string;
  "verified"?: boolean;
  "branchId"?: any;
  "password"?: string;
  accessTokens?: any[];
  vendor?: Vendor;
  profile?: Profile;
}

export class User implements UserInterface {
  "ic": string;
  "dob": string;
  "vendorId": any;
  "profileId": any;
  "facebookId": string;
  "googleId": string;
  "phoneId": string;
  "appleId": string;
  "telegramClientId": string;
  "plainEmail": string;
  "realm": string;
  "username": string;
  "email": string;
  "emailVerified": boolean;
  "id": any;
  "role": string;
  "verificationCode": string;
  "verified": boolean;
  "branchId": any;
  "password": string;
  accessTokens: any[];
  vendor: Vendor;
  profile: Profile;
  constructor(data?: UserInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `User`.
   */
  public static getModelName() {
    return "User";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of User for dynamic purposes.
  **/
  public static factory(data: UserInterface): User{
    return new User(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'User',
      plural: 'Users',
      path: 'Users',
      idName: 'id',
      properties: {
        "ic": {
          name: 'ic',
          type: 'string'
        },
        "dob": {
          name: 'dob',
          type: 'string'
        },
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "profileId": {
          name: 'profileId',
          type: 'any'
        },
        "facebookId": {
          name: 'facebookId',
          type: 'string'
        },
        "googleId": {
          name: 'googleId',
          type: 'string'
        },
        "phoneId": {
          name: 'phoneId',
          type: 'string'
        },
        "appleId": {
          name: 'appleId',
          type: 'string'
        },
        "telegramClientId": {
          name: 'telegramClientId',
          type: 'string'
        },
        "plainEmail": {
          name: 'plainEmail',
          type: 'string'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "role": {
          name: 'role',
          type: 'string'
        },
        "verificationCode": {
          name: 'verificationCode',
          type: 'string'
        },
        "verified": {
          name: 'verified',
          type: 'boolean',
          default: false
        },
        "branchId": {
          name: 'branchId',
          type: 'any'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
        profile: {
          name: 'profile',
          type: 'Profile',
          model: 'Profile',
          relationType: 'belongsTo',
                  keyFrom: 'profileId',
          keyTo: 'id'
        },
      }
    }
  }
}
