import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RecentOrderRequestComponent} from './recent-order-request/recent-order-request.component';
import {AddEditPrinterComponent} from './modals/add-edit-printer/add-edit-printer.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ConfirmDialogComponent } from './modals/confirm-dialog/confirm-dialog.component';
import {NgbModalModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AlertsComponent } from './alerts/alerts.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {RouterModule} from '@angular/router';
import { OrderSummaryComponent } from './modals/order-summary/order-summary.component';


@NgModule({
  declarations: [RecentOrderRequestComponent, AddEditPrinterComponent, ConfirmDialogComponent, AlertsComponent, OrderSummaryComponent],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    PerfectScrollbarModule
  ],
    exports: [
        RecentOrderRequestComponent,
        AddEditPrinterComponent,
        ConfirmDialogComponent,
        AlertsComponent,
        OrderSummaryComponent
    ]
})
export class ComponentsModule {
}
