/* tslint:disable */
import {
  Vendor,
  GeoPoint
} from '../index';

declare var Object: any;
export interface ConfigInterface {
  "vendorId": any;
  "companyName"?: string;
  "companyRegistrationNo"?: string;
  "name"?: string;
  "stockSheetUrl"?: string;
  "monthlyProjection"?: number;
  "deliveryNote"?: string;
  "fpxEnabled"?: boolean;
  "creditCardEnabled"?: boolean;
  "directDepositEnabled"?: boolean;
  "walletEnabled"?: boolean;
  "paydibsWalletEnabled"?: boolean;
  "ipay88WalletEnabled"?: boolean;
  "tags"?: Array<any>;
  "taxPercent"?: number;
  "taxName"?: string;
  "announcement"?: string;
  "status"?: number;
  "aboutUs"?: string;
  "email"?: string;
  "coordinates"?: GeoPoint;
  "senderEmail"?: string;
  "whatsappNo"?: string;
  "website"?: string;
  "facebook"?: string;
  "youtube"?: string;
  "instagram"?: string;
  "phone"?: string;
  "country"?: string;
  "openingHour"?: string;
  "closingHour"?: string;
  "currency"?: any;
  "directDepositInstructions"?: string;
  "cod"?: any;
  "wallet"?: any;
  "paypal"?: any;
  "bankTransfer"?: any;
  "stripe"?: any;
  "tax"?: any;
  "minimumOrderAmount"?: number;
  "minimumCreditCardAmount"?: number;
  "tnc"?: string;
  "privacyPolicy"?: string;
  "deliveryPolicy"?: string;
  "refundPolicy"?: string;
  "streetTypes"?: Array<any>;
  "states"?: Array<any>;
  "clientUrl"?: string;
  "leadTimes"?: Array<any>;
  "uoms"?: Array<any>;
  "defaultDeliveryTimes"?: Array<any>;
  "voucherOptions"?: any;
  "updatedAt"?: Date;
  "updatedBy"?: any;
  "id"?: any;
  vendor?: Vendor;
}

export class Config implements ConfigInterface {
  "vendorId": any;
  "companyName": string;
  "companyRegistrationNo": string;
  "name": string;
  "stockSheetUrl": string;
  "monthlyProjection": number;
  "deliveryNote": string;
  "fpxEnabled": boolean;
  "creditCardEnabled": boolean;
  "directDepositEnabled": boolean;
  "walletEnabled": boolean;
  "paydibsWalletEnabled": boolean;
  "ipay88WalletEnabled": boolean;
  "tags": Array<any>;
  "taxPercent": number;
  "taxName": string;
  "announcement": string;
  "status": number;
  "aboutUs": string;
  "email": string;
  "coordinates": GeoPoint;
  "senderEmail": string;
  "whatsappNo": string;
  "website": string;
  "facebook": string;
  "youtube": string;
  "instagram": string;
  "phone": string;
  "country": string;
  "openingHour": string;
  "closingHour": string;
  "currency": any;
  "directDepositInstructions": string;
  "cod": any;
  "wallet": any;
  "paypal": any;
  "bankTransfer": any;
  "stripe": any;
  "tax": any;
  "minimumOrderAmount": number;
  "minimumCreditCardAmount": number;
  "tnc": string;
  "privacyPolicy": string;
  "deliveryPolicy": string;
  "refundPolicy": string;
  "streetTypes": Array<any>;
  "states": Array<any>;
  "clientUrl": string;
  "leadTimes": Array<any>;
  "uoms": Array<any>;
  "defaultDeliveryTimes": Array<any>;
  "voucherOptions": any;
  "updatedAt": Date;
  "updatedBy": any;
  "id": any;
  vendor: Vendor;
  constructor(data?: ConfigInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Config`.
   */
  public static getModelName() {
    return "Config";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Config for dynamic purposes.
  **/
  public static factory(data: ConfigInterface): Config{
    return new Config(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Config',
      plural: 'configs',
      path: 'configs',
      idName: 'id',
      properties: {
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "companyName": {
          name: 'companyName',
          type: 'string'
        },
        "companyRegistrationNo": {
          name: 'companyRegistrationNo',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string',
          default: 'Kobo Together'
        },
        "stockSheetUrl": {
          name: 'stockSheetUrl',
          type: 'string'
        },
        "monthlyProjection": {
          name: 'monthlyProjection',
          type: 'number',
          default: 17000000
        },
        "deliveryNote": {
          name: 'deliveryNote',
          type: 'string'
        },
        "fpxEnabled": {
          name: 'fpxEnabled',
          type: 'boolean'
        },
        "creditCardEnabled": {
          name: 'creditCardEnabled',
          type: 'boolean'
        },
        "directDepositEnabled": {
          name: 'directDepositEnabled',
          type: 'boolean'
        },
        "walletEnabled": {
          name: 'walletEnabled',
          type: 'boolean'
        },
        "paydibsWalletEnabled": {
          name: 'paydibsWalletEnabled',
          type: 'boolean'
        },
        "ipay88WalletEnabled": {
          name: 'ipay88WalletEnabled',
          type: 'boolean'
        },
        "tags": {
          name: 'tags',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "taxPercent": {
          name: 'taxPercent',
          type: 'number',
          default: 0
        },
        "taxName": {
          name: 'taxName',
          type: 'string',
          default: 'GST'
        },
        "announcement": {
          name: 'announcement',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "aboutUs": {
          name: 'aboutUs',
          type: 'string',
          default: ''
        },
        "email": {
          name: 'email',
          type: 'string',
          default: 'admin@kobopremium.com'
        },
        "coordinates": {
          name: 'coordinates',
          type: 'GeoPoint'
        },
        "senderEmail": {
          name: 'senderEmail',
          type: 'string',
          default: 'no_reply@kobopremium.com'
        },
        "whatsappNo": {
          name: 'whatsappNo',
          type: 'string'
        },
        "website": {
          name: 'website',
          type: 'string'
        },
        "facebook": {
          name: 'facebook',
          type: 'string'
        },
        "youtube": {
          name: 'youtube',
          type: 'string'
        },
        "instagram": {
          name: 'instagram',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "country": {
          name: 'country',
          type: 'string',
          default: 'Malaysia'
        },
        "openingHour": {
          name: 'openingHour',
          type: 'string',
          default: '09:00 AM'
        },
        "closingHour": {
          name: 'closingHour',
          type: 'string',
          default: '11:00 PM'
        },
        "currency": {
          name: 'currency',
          type: 'any',
          default: <any>null
        },
        "directDepositInstructions": {
          name: 'directDepositInstructions',
          type: 'string'
        },
        "cod": {
          name: 'cod',
          type: 'any',
          default: <any>null
        },
        "wallet": {
          name: 'wallet',
          type: 'any',
          default: <any>null
        },
        "paypal": {
          name: 'paypal',
          type: 'any',
          default: <any>null
        },
        "bankTransfer": {
          name: 'bankTransfer',
          type: 'any',
          default: <any>null
        },
        "stripe": {
          name: 'stripe',
          type: 'any',
          default: <any>null
        },
        "tax": {
          name: 'tax',
          type: 'any',
          default: <any>null
        },
        "minimumOrderAmount": {
          name: 'minimumOrderAmount',
          type: 'number',
          default: 5
        },
        "minimumCreditCardAmount": {
          name: 'minimumCreditCardAmount',
          type: 'number',
          default: 30
        },
        "tnc": {
          name: 'tnc',
          type: 'string'
        },
        "privacyPolicy": {
          name: 'privacyPolicy',
          type: 'string'
        },
        "deliveryPolicy": {
          name: 'deliveryPolicy',
          type: 'string'
        },
        "refundPolicy": {
          name: 'refundPolicy',
          type: 'string'
        },
        "streetTypes": {
          name: 'streetTypes',
          type: 'Array&lt;any&gt;'
        },
        "states": {
          name: 'states',
          type: 'Array&lt;any&gt;'
        },
        "clientUrl": {
          name: 'clientUrl',
          type: 'string',
          default: 'https://www.kobopremium.com'
        },
        "leadTimes": {
          name: 'leadTimes',
          type: 'Array&lt;any&gt;'
        },
        "uoms": {
          name: 'uoms',
          type: 'Array&lt;any&gt;'
        },
        "defaultDeliveryTimes": {
          name: 'defaultDeliveryTimes',
          type: 'Array&lt;any&gt;'
        },
        "voucherOptions": {
          name: 'voucherOptions',
          type: 'any'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
      }
    }
  }
}
