// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  appVersion: 'development',
  production: false,
  firebase: {
    apiKey: "AIzaSyClgDcULTdtMuP04h7FdA6ba2_FzL31Vzc",
    authDomain: "kobobakery-e5b72.firebaseapp.com",
    projectId: "kobobakery-e5b72",
    storageBucket: "kobobakery-e5b72.appspot.com",
    messagingSenderId: "112782876143",
    appId: "1:112782876143:web:1ebbc2cc426dca6b676a1a",
    measurementId: "G-B9EJ04W802"
  },
  loopback: {
    backendUrl: "https://api.kobotogether.com", //"http://localhost:3000",
    apiUrl: "https://api.kobotogether.com/api", //"http:/localhost:3000/api",
    apiKey: "abc123!@#"
  },
  youtubeApiKey: "AIzaSyDOHxAGzglT2buaRbWPX_UG2lJD46V0uyY",
  mapsApiKey: "AIzaSyA93qiLbF0ri44GKweIh5nE8VJZ5eepTgc"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
