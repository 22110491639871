<div class="authincation h-100">
  <div class="container vh-100">
    <div class="row justify-content-center h-100 align-items-center">
      <div class="col-lg-6 col-md-8">
        <div class="authincation-content">
          <div class="row no-gutters">
            <div class="col-xl-12">
              <div class="auth-form">
                <!--<div class="text-center mb-3">
                  <a routerLink="." class="noble-ui-logo d-block mb-2">Kobo<span>Branch</span></a>
                </div> -->

                <p class="small text-center">WebVersion: <span>{{appVersion}}</span></p>

                <!-- present a select box to select branch from the branches in the login component -->
                <div class="form-group">
                  <label class="mb-1"><strong>Branch</strong></label>
                  <select class="form-control" name="branch" [(ngModel)]="selectedBranch" (change)="onBranchSelected()">
                    <option *ngFor="let branch of configService.branches | async" [value]="branch.id">{{branch.address.displayName}}</option>
                  </select>
                </div>

                <div>
                  <h4 class="text-center mb-4">Sign in your branch account</h4>
                  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">


                      <mat-form-field appearance="fill" class="col-lg-12 col-md-12 col-sm-12 mx-0 px-0">
                        <mat-label>Staff Account</mat-label>
                        <mat-select name="login[email]" formControlName="email" required>
                          <mat-option *ngFor="let staff of branchStaffs" [value]="staff.user.email">
                            {{staff.displayName}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="loginForm.hasError('required')">Please select branch staff</mat-error>
                      </mat-form-field>

                      <!--<label class="mb-1"><strong>Email</strong></label>
                      <input type="email" class="form-control" name="login[email]" formControlName="email">-->

                    <div class="form-group">
                      <label class="mb-1"><strong>Password</strong></label>
                      <input type="password" class="form-control" name="login[password]" formControlName="password">
                    </div>
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary btn-block">Sign Me In</button>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
