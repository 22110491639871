/* tslint:disable */
/**
* @module SDKModule
* @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
* @license MIT 2016 Jonathan Casarrubias
* @version 2.1.0
* @description
* The SDKModule is a generated Software Development Kit automatically built by
* the LoopBack SDK Builder open source module.
*
* The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
* can import this Software Development Kit as follows:
*
*
* APP Route Module Context
* ============================================================================
* import { NgModule }       from '@angular/core';
* import { BrowserModule }  from '@angular/platform-browser';
* // App Root 
* import { AppComponent }   from './app.component';
* // Feature Modules
* import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
* // Import Routing
* import { routing }        from './app.routing';
* @NgModule({
*  imports: [
*    BrowserModule,
*    routing,
*    SDK[Browser|Node|Native]Module.forRoot()
*  ],
*  declarations: [ AppComponent ],
*  bootstrap:    [ AppComponent ]
* })
* export class AppModule { }
*
**/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { SocketBrowser } from './sockets/socket.browser';
import { SocketDriver } from './sockets/socket.driver';
import { SocketConnection } from './sockets/socket.connections';
import { RealTime } from './services/core/real.time';
import { UserApi } from './services/custom/User';
import { VendorApi } from './services/custom/Vendor';
import { OrderApi } from './services/custom/Order';
import { ProfileApi } from './services/custom/Profile';
import { CategoryApi } from './services/custom/Category';
import { ProductApi } from './services/custom/Product';
import { AttributeGroupApi } from './services/custom/AttributeGroup';
import { BranchApi } from './services/custom/Branch';
import { VoucherApi } from './services/custom/Voucher';
import { SubcategoryApi } from './services/custom/Subcategory';
import { CollectionApi } from './services/custom/Collection';
import { FeedApi } from './services/custom/Feed';
import { ImageFileApi } from './services/custom/ImageFile';
import { ConfigApi } from './services/custom/Config';
import { DeliveryAddressApi } from './services/custom/DeliveryAddress';
import { AddOnGroupApi } from './services/custom/AddOnGroup';
import { CheckoutSessionApi } from './services/custom/CheckoutSession';
import { DeliveryApi } from './services/custom/Delivery';
import { TransactionApi } from './services/custom/Transaction';
import { ProductReviewApi } from './services/custom/ProductReview';
import { TelegramRegistrationApi } from './services/custom/TelegramRegistration';
import { DirectDepositApi } from './services/custom/DirectDeposit';
import { SalesCampaignApi } from './services/custom/SalesCampaign';
import { TagApi } from './services/custom/Tag';
import { TerminalApi } from './services/custom/Terminal';
import { PrinterApi } from './services/custom/Printer';
import { UserGroupApi } from './services/custom/UserGroup';
import { OfflineDataLogApi } from './services/custom/OfflineDataLog';
import { ProductInventoryApi } from './services/custom/ProductInventory';
import { RefundApi } from './services/custom/Refund';
import { CouponApi } from './services/custom/Coupon';
/**
* @module SDKBrowserModule
* @description
* This module should be imported when building a Web Application in the following scenarios:
*
*  1.- Regular web application
*  2.- Angular universal application (Browser Portion)
*  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
**/
@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [ ],
  exports:      [ ],
  providers:    [
    ErrorHandler,
    SocketConnection
  ]
})
export class SDKBrowserModule {
  static forRoot(internalStorageProvider: any = {
    provide: InternalStorage,
    useClass: CookieBrowser
  }): ModuleWithProviders<any> { // updated by tvx
    return {
      ngModule  : SDKBrowserModule,
      providers : [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        RealTime,
        UserApi,
        VendorApi,
        OrderApi,
        ProfileApi,
        CategoryApi,
        ProductApi,
        AttributeGroupApi,
        BranchApi,
        VoucherApi,
        SubcategoryApi,
        CollectionApi,
        FeedApi,
        ImageFileApi,
        ConfigApi,
        DeliveryAddressApi,
        AddOnGroupApi,
        CheckoutSessionApi,
        DeliveryApi,
        TransactionApi,
        ProductReviewApi,
        TelegramRegistrationApi,
        DirectDepositApi,
        SalesCampaignApi,
        TagApi,
        TerminalApi,
        PrinterApi,
        UserGroupApi,
        OfflineDataLogApi,
        ProductInventoryApi,
        RefundApi,
        CouponApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser },
        { provide: SocketDriver, useClass: SocketBrowser }
      ]
    };
  }
}
/**
* Have Fun!!!
* - Jon
**/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';

