import {Injectable, NgZone} from '@angular/core';
import {Product, ProductApi, Subcategory, SubcategoryApi} from '../../shared/sdk';
import {BehaviorSubject} from 'rxjs';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {IpcService} from '../../shared/services/ipc/ipc.service';
import {first, take} from 'rxjs/operators';
import {FirestoreService} from '../../shared/services/firestore/firestore.service';
import {LoggerService} from '../../shared/services/logger.service';
import {liveSearch} from '../../utilities/constants';
import {InternalApiService} from '../../shared/services/internalapi/internalapi.service';
import {AuthenticationService} from '../../authentication/authentication.service';
import {NgxSpinnerService} from 'ngx-spinner';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class ProductsListService {

  routeParams: any;
  public Currency = {name: 'MYR', currency: 'RM', price: 1}; // Default Currency
  public chefsPicks: Product[] = [];
  public featuredProducts: Product[] = [];
  public categories: any[] = [];
  public onProductsChanged: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  public onSubcategoriesChanged: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  public currentSubcategory: BehaviorSubject<string> = new BehaviorSubject<string>('all');
  public favorites: Array<string> = [];
  todayStruct: NgbDateStruct;

  public filter: any = {
    where: '1 = 1',
    orderBy: 'ordering ASC',
    limit: 20,
    skip: 0,
    productsCount: 0,
    currentPage: 1
  };

  filterSelection: BehaviorSubject<any> = new BehaviorSubject<any>({
    categoryId: null,
    subcategoryId: null
  });

  currentPage: number = 1;
  limit: number = 20;
  productsCount: number = 0;
  allProductsCount: number = 0;
  allProducts: BehaviorSubject<Array<Product>> = new BehaviorSubject<Array<Product>>([]);

  searchField: BehaviorSubject<string> = new BehaviorSubject<string>('');
  unavailabilityMatrixChanged: BehaviorSubject<any> = new BehaviorSubject({});
  stockData: BehaviorSubject<Array<{ id: string, dailyStockLimit: number, leadTime: string, isOutOfStock: boolean, attributeStock?: Array<{ id: string, name: string, currentStock: number }> }>> = new BehaviorSubject([]);

  constructor(private ipcService: IpcService,
              private internalApi: InternalApiService,
              private firestore: FirestoreService,
              private _productApi: ProductApi,
              private _subcategoryApi: SubcategoryApi,
              private logger: LoggerService,
              private _spinner: NgxSpinnerService,
              private _authService: AuthenticationService,
              private zone: NgZone) {
    this.searchField
      .pipe(
        liveSearch(search => {this.currentPage = 1; return this.loadProducts();})
      ).subscribe();

  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    this.routeParams = route.params;
    this.currentPage = 1;
    //this.subscribeToAvailabilityMatrix();
    await this._authService.checkUserAuthStatus();
    let promises: any[] = [this.loadSubcategories(), this.loadProducts(), this.loadBranchStock()]; // [this.countProducts(), this.loadSubcategories(), this.loadProducts()];
    return Promise.all(promises);
  }

  async loadBranchStock(): Promise<void> {
    try {
      let data: any = await this._productApi.getDayStockForBranch({
        branchId: this.ipcService.branchInformation.value?.id,
        date: moment().format('YYYY-MM-DD')
      }).toPromise();
      console.log('stockData', data);
      this.stockData.next(data);
      return Promise.resolve();
    } catch (e) {
      console.log(e);
      return Promise.reject();
    }
  }

  subscribeToAvailabilityMatrix(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ipcService.branchInformation
        .subscribe(branch => {
          if (branch) {
            this.firestore.doc$(`unavailabilityMatrix/${branch.id}`)
              .subscribe((matrix: any) => {
                this.zone.run(() => {
                  this.unavailabilityMatrixChanged.next(matrix?.data || {});
                });
                resolve();
              }, e => {
                this.logger.displayMessage(e.message, 'Error', 'error');
                reject(e);
              });
          }
        });
    });

  }

  async updateAvailabilityMatrix(matrix: any): Promise<any> {
    try {
      return this.firestore.upsert(`unavailabilityMatrix/${this.ipcService.branchInformation.value.id}`, {data: matrix});
    } catch (e) {
      this.logger.displayMessage(e.message, 'Error', 'error');
      return Promise.reject(e);
    }
  }

  async countProducts(): Promise<any> {
    try {
      let filter: any = {
        q: this.searchField.value, // search string ::
        and: [
          {status: 0},
          {vendorId: this._authService.loggedInUser.value?.user?.vendorId?.toString()}
        ]
      };
      if (this.searchField.value.length < 2) {
        delete (filter.q);
      }
      if (this.filterSelection.value?.subcategoryId && this.filterSelection.value?.subcategoryId.length > 0) {
        filter.and.push({subcategoryId: this.filterSelection.value?.subcategoryId});
      }
      if (this.filterSelection.value?.categoryId && this.filterSelection.value?.categoryId.length > 0) {
        filter.and.push({categoryId: this.filterSelection.value?.categoryId});
      }
      console.log('countFilter', filter);
      //let allProductsCount: { count: number } = await this._productApi.count({status: 0}).toPromise();
      //this.allProductsCount = allProductsCount.count;
      let productsCount: { count: number } = await this._productApi.count(filter).toPromise();
      this.productsCount = productsCount.count;
      console.log('this.productsCount', this.productsCount);
    } catch (e) {
      this.logger.displayMessage(e.message, 'Error', 'error');
      this.productsCount = 0;
      return Promise.reject(e);
    }
  }

  /*
  async countProducts(): Promise<number> {
    try {
      let query: string = 'SELECT COUNT(1) FROM public.tbl_products as count;';
      let results = await this.internalApi.dbQuery(query);

      if (!(results && results.length > 0)) {
        this.filter.productsCount = 0;
        return Promise.resolve(0);
      }

      this.filter.productsCount = Number(results[0].count);
      return Promise.resolve(this.filter.productsCount);

    } catch (e) {
      this.logger.displayMessage(e.message, 'Error', '');
      return Promise.reject(e);
    }
  }
  */

  async loadAllProducts(): Promise<any> {
    try {
      let products: Product[] = await this._productApi.find<Product>({
        where: {
          and: [
            {isActive: true},
            {status: 0},
            {vendorId: this._authService.loggedInUser.value?.user?.vendorId?.toString()}
          ]
        },
        order: 'ordering ASC',
        include: ['subcategory', 'addOnGroup'],
      }).toPromise();
      this.allProducts.next(products);
      return Promise.resolve('products reloaded');
    } catch (e) {
      return Promise.reject(e.message);
    }
  }

  /*loadProducts(isInfiniteScroll?: boolean): Promise<any> {
    this.ipcService.productsQueryResponse.next(null);
    this.ipcService.productsQueryResponse
      .pipe(take(2))
      .subscribe(products => {
        if (products) {
          if(isInfiniteScroll)
            products = [...this.onProductsChanged.value, ...products];
          this.onProductsChanged.next(products);
        }
      });

    this.filter.skip = (this.filter.currentPage - 1) * this.filter.limit;
    this.ipcService.queryProducts({
      ...this.filter,
      where: this.filter.where + ` AND lower(concat(a.name, a.subcategoryname, b.leadtime, a.code)) like '%${(this.searchField.value || '').toLowerCase()}%' `
    });
    return Promise.resolve();
  }




  async loadSingleProduct(productId: string): Promise<any> {
    try {
      let response = await this.internalApi.queryProducts({where: `a.id = '${productId}'`, orderBy: 'id ASC', skip: 0, limit: 1});
      console.log('loadSingleProduct', response);
      if(response && response.length > 0) {
        let productsInView = this.onProductsChanged.value || [];
        let index = productsInView.findIndex(x => x.id === response[0].id);
        if(index > -1) {
          productsInView[index] = response[0];
          this.onProductsChanged.next(productsInView);
        }
      }
    } catch (e) {
      this.logger.displayMessage(e.message, 'Error', 'error');
      return Promise.reject(e);
    }
  }

   */

  async loadProducts(isInfiniteScroll?: boolean): Promise<any> {
    this.logger.loadingText = 'Loading Products ...';
    await this._spinner.show();
    //this.loadAllProducts().then(console.log).catch(console.log);
    if (!isInfiniteScroll) {
      await this.countProducts();
    }
    console.log('filtering products :: ' + this.searchField.value);
    try {
      let filter: any = {
        where: {
          q: this.searchField.value, // search string ::
          and: [
            {status: 0},
            {vendorId: this._authService.loggedInUser.value?.user?.vendorId?.toString()}
          ]
        },
        order: ['ordering ASC', 'name ASC'],
        include: ['subcategory', 'addOnGroup'],
        limit: this.limit,
        skip: ((this.currentPage - 1) * this.limit)
      };
      if (this.searchField.value.length < 2) {
        delete (filter.where.q);
      }
      if (this.filterSelection.value?.subcategoryId && this.filterSelection.value?.subcategoryId.length > 0) {
        filter.where.and.push({subcategoryId: this.filterSelection.value?.subcategoryId});
      }
      if (this.filterSelection.value?.categoryId && this.filterSelection.value?.categoryId.length > 0) {
        filter.where.and.push({categoryId: this.filterSelection.value?.categoryId});
      }

      let products: Product[] = await this._productApi.find<Product>(filter).toPromise();
      if(isInfiniteScroll)
        products = [...this.onProductsChanged.value, ...products];
      this.onProductsChanged.next(products);

      await this._spinner.hide();
      this.logger.resetLoadingText();
      return Promise.resolve(products);
    } catch (e) {
      await this._spinner.hide();
      this.logger.resetLoadingText();
      this.logger.displayMessage(e.message, 'Error', 'error');
      this.onProductsChanged.next([]);
      return Promise.reject(e);
    }
  }

  async loadSubcategories(): Promise<any> {
    let subcategories: Subcategory[] = await this._subcategoryApi.find<Subcategory>({where: {and: [{status: 0}, {isActive: true}]}}).toPromise();
    this.onSubcategoriesChanged.next(subcategories);
    /*
    this.ipcService.subcategoriesQueryResponse.next(null);
    this.ipcService.subcategoriesQueryResponse
      .pipe(take(2))
      .subscribe(subcategories => {
        if (subcategories) {
          this.onSubcategoriesChanged.next(subcategories);
        }
      });
    this.ipcService.querySubcategories({where: '1=1', orderBy: 'ordering asc', skip: 0, limit: 1000});
    */
    return Promise.resolve();
  }


  resetFilters(): Promise<any> {
    this.searchField.next('');
    this.filter = {
      where: '1 = 1',
      orderBy: 'ordering ASC',
      limit: 20,
      skip: 0,
      productsCount: 0,
      currentPage: 1
    };
    return this.countProducts();
  }

}
