import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {AuthenticationService} from "../authentication.service";
import {LoggerService} from "../../shared/services/logger.service";
import {IpcService} from '../../shared/services/ipc/ipc.service';
import {Branch, BranchApi, Profile} from '../../shared/sdk';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ConfigService} from '../../shared/services/config.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  returnUrl: any;
  public loginForm: FormGroup;
  public branchStaffs: Array<Profile> = [];
  private _unsubscribe: Subject<any>;
  appVersion: string = 'Unavailable';
  selectedBranch: string;
  constructor(private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private _authService: AuthenticationService,
              private spinner: NgxSpinnerService,
              public ipcService: IpcService,
              public configService: ConfigService,
              private branchApi: BranchApi,
              public logger: LoggerService) {
  }

  ngOnInit(): void {
    this._unsubscribe = new Subject();
    this.appVersion = environment.appVersion;
    this.selectedBranch = localStorage.getItem('selectedBranch');
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.createLoginForm();
    //this.ipcService.getTerminalBranch();
    this._authService.loadBranchStaffs();
    this._authService.onBranchStaffChanged
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(branchStaffs => {
        console.log('loaded branch staffs', branchStaffs);
        if(branchStaffs) {

          this.branchStaffs = branchStaffs;

          //this.loginForm.get('email').setValue(this.branchStaffs[0].user.email);
        }
      });
  }

  async onBranchSelected(): Promise<any> {
    try {
      let branch: Branch = this.configService.branches.value.find(x => x.id === this.selectedBranch);
      console.log('branch selected', branch);
      this.ipcService.branchInformation.next(branch);
      //await this.spinner.show();
      //await this.spinner.hide();
    } catch (e) {
      await this.spinner.hide();
      return Promise.reject(e);
    }
  }

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.email]],
      password: ['', [Validators.minLength(6)]],
    });
  }

  async onSubmit(): Promise<any> {
    await this.spinner.show();
    try {
      console.log('logging in with email!');
      let credentials = this.loginForm.getRawValue();
      console.log('credentials', credentials);
      credentials.email = credentials.email.trim();
      credentials.password = credentials.password.trim();
      let response = await this._authService.signinWithFirebaseEmail(credentials.email, credentials.password);
      await this.spinner.hide();
      localStorage.setItem('selectedBranch', this.selectedBranch);
      //return this.router.navigate(['/dashboard']);
      window.location.reload();
    } catch (e) {
      await this.spinner.hide();
      this.logger.displayMessage(e.message, 'Failed', 'error');
      return Promise.reject(e);
    }
  }

  formatBranchAddress(branch: any): string {
    if (!branch?.address) {
      return '';
    }
    return `<b>${branch.address.displayName}</b><br><b>Tel:</b> ${branch.address.phone}<br><b>Email:</b> ${branch.address.email}<br><b>Address: </b>${branch.address.addressLine1} ${branch.address.addressLine2}<br>${branch.address.street}<br>${branch.address.city}, ${branch.address.postCode}<br>${branch.address.state} Malaysia.`;
  }


  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
