/* tslint:disable */
import {
  User,
  Order,
  Category,
  Product,
  Branch,
  Voucher,
  Subcategory,
  Collection,
  Feed,
  Config,
  AddOnGroup,
  Delivery,
  Transaction,
  ProductReview,
  SalesCampaign,
  Terminal,
  Printer,
  UserGroup,
  ProductInventory,
  Coupon
} from '../index';

declare var Object: any;
export interface VendorInterface {
  "businessName": string;
  "shortName": string;
  "phone": string;
  "address"?: any;
  "createdAt": Date;
  "activatedAt"?: Date;
  "updatedAt"?: Date;
  "updatedBy"?: any;
  "status": number;
  "profileCompletedRating"?: number;
  "logo"?: any;
  "banner"?: any;
  "businessType"?: string;
  "classTypeId"?: number;
  "classType"?: string;
  "businessNature"?: string;
  "businessCategoryId"?: number;
  "businessCategory"?: string;
  "companyRegNo"?: string;
  "statusDescription"?: string;
  "youtubeChannelId"?: string;
  "id"?: any;
  user?: User[];
  order?: Order[];
  vendorBankDetail?: any[];
  category?: Category[];
  product?: Product[];
  branch?: Branch[];
  voucher?: Voucher[];
  subcategory?: Subcategory[];
  collection?: Collection[];
  feed?: Feed[];
  config?: Config[];
  addOnGroup?: AddOnGroup[];
  delivery?: Delivery[];
  transaction?: Transaction[];
  productReview?: ProductReview[];
  salesCampaign?: SalesCampaign[];
  terminal?: Terminal[];
  printer?: Printer[];
  userGroup?: UserGroup[];
  productInventory?: ProductInventory[];
  coupon?: Coupon[];
}

export class Vendor implements VendorInterface {
  "businessName": string;
  "shortName": string;
  "phone": string;
  "address": any;
  "createdAt": Date;
  "activatedAt": Date;
  "updatedAt": Date;
  "updatedBy": any;
  "status": number;
  "profileCompletedRating": number;
  "logo": any;
  "banner": any;
  "businessType": string;
  "classTypeId": number;
  "classType": string;
  "businessNature": string;
  "businessCategoryId": number;
  "businessCategory": string;
  "companyRegNo": string;
  "statusDescription": string;
  "youtubeChannelId": string;
  "id": any;
  user: User[];
  order: Order[];
  vendorBankDetail: any[];
  category: Category[];
  product: Product[];
  branch: Branch[];
  voucher: Voucher[];
  subcategory: Subcategory[];
  collection: Collection[];
  feed: Feed[];
  config: Config[];
  addOnGroup: AddOnGroup[];
  delivery: Delivery[];
  transaction: Transaction[];
  productReview: ProductReview[];
  salesCampaign: SalesCampaign[];
  terminal: Terminal[];
  printer: Printer[];
  userGroup: UserGroup[];
  productInventory: ProductInventory[];
  coupon: Coupon[];
  constructor(data?: VendorInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Vendor`.
   */
  public static getModelName() {
    return "Vendor";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Vendor for dynamic purposes.
  **/
  public static factory(data: VendorInterface): Vendor{
    return new Vendor(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Vendor',
      plural: 'vendors',
      path: 'vendors',
      idName: 'id',
      properties: {
        "businessName": {
          name: 'businessName',
          type: 'string'
        },
        "shortName": {
          name: 'shortName',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "address": {
          name: 'address',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "activatedAt": {
          name: 'activatedAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 1
        },
        "profileCompletedRating": {
          name: 'profileCompletedRating',
          type: 'number',
          default: 0
        },
        "logo": {
          name: 'logo',
          type: 'any'
        },
        "banner": {
          name: 'banner',
          type: 'any'
        },
        "businessType": {
          name: 'businessType',
          type: 'string'
        },
        "classTypeId": {
          name: 'classTypeId',
          type: 'number'
        },
        "classType": {
          name: 'classType',
          type: 'string'
        },
        "businessNature": {
          name: 'businessNature',
          type: 'string'
        },
        "businessCategoryId": {
          name: 'businessCategoryId',
          type: 'number'
        },
        "businessCategory": {
          name: 'businessCategory',
          type: 'string'
        },
        "companyRegNo": {
          name: 'companyRegNo',
          type: 'string'
        },
        "statusDescription": {
          name: 'statusDescription',
          type: 'string'
        },
        "youtubeChannelId": {
          name: 'youtubeChannelId',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'User[]',
          model: 'User',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        order: {
          name: 'order',
          type: 'Order[]',
          model: 'Order',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        vendorBankDetail: {
          name: 'vendorBankDetail',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        category: {
          name: 'category',
          type: 'Category[]',
          model: 'Category',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        product: {
          name: 'product',
          type: 'Product[]',
          model: 'Product',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        branch: {
          name: 'branch',
          type: 'Branch[]',
          model: 'Branch',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        voucher: {
          name: 'voucher',
          type: 'Voucher[]',
          model: 'Voucher',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        subcategory: {
          name: 'subcategory',
          type: 'Subcategory[]',
          model: 'Subcategory',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        collection: {
          name: 'collection',
          type: 'Collection[]',
          model: 'Collection',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        feed: {
          name: 'feed',
          type: 'Feed[]',
          model: 'Feed',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        config: {
          name: 'config',
          type: 'Config[]',
          model: 'Config',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        addOnGroup: {
          name: 'addOnGroup',
          type: 'AddOnGroup[]',
          model: 'AddOnGroup',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        delivery: {
          name: 'delivery',
          type: 'Delivery[]',
          model: 'Delivery',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        transaction: {
          name: 'transaction',
          type: 'Transaction[]',
          model: 'Transaction',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        productReview: {
          name: 'productReview',
          type: 'ProductReview[]',
          model: 'ProductReview',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        salesCampaign: {
          name: 'salesCampaign',
          type: 'SalesCampaign[]',
          model: 'SalesCampaign',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        terminal: {
          name: 'terminal',
          type: 'Terminal[]',
          model: 'Terminal',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        printer: {
          name: 'printer',
          type: 'Printer[]',
          model: 'Printer',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        userGroup: {
          name: 'userGroup',
          type: 'UserGroup[]',
          model: 'UserGroup',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        productInventory: {
          name: 'productInventory',
          type: 'ProductInventory[]',
          model: 'ProductInventory',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
        coupon: {
          name: 'coupon',
          type: 'Coupon[]',
          model: 'Coupon',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'vendorId'
        },
      }
    }
  }
}
