import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactsComponent} from './contacts/contacts.component';
import {ConversationComponent} from './conversation/conversation.component';
import {ContactsService} from './contacts/contacts.service';
import {ConversationService} from './conversation/conversation.service';
import { ChatComponent } from './chat/chat.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {FormsModule} from '@angular/forms';


@NgModule({
  declarations: [ContactsComponent, ConversationComponent, ChatComponent],
  exports: [
    ChatComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    PerfectScrollbarModule
  ],
  providers: [
    ContactsService,
    ConversationService
  ],
})
export class ChatModule {
}
