export class SIGNATURE {
  id: string;
  displayName: string;
  email: string;

  constructor(user) {
    this.id = user.id;
    this.displayName = user.profile.displayName;
    this.email = user.profile.plainEmail;
  }

  get signature (): any {
    return {
      id: this.id,
      displayName: this.displayName,
      email: this.email
    }
  }
}
